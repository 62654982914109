import {
  Button,
  Card,
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Skeleton,
  Table,
  Typography,
} from 'antd';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import { applySort, isDisableFilter } from '../../../utils/common.utils';
import axios, { CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';

import { AllLicenseResponse } from '../../../types/response/all-license.type';
import { ColumnsType } from 'antd/es/table';
import DaysToExpireCell from '../../../components/licenses/daysToExpireCell';
import { FilterOutlined } from '@ant-design/icons';
import { IdConstants } from '../../../constants/id.constants';
import LcLoaCell from '../../../components/licenses/lcLoaCell';
import { LicenseStatus } from '../../../enums/licenseStatus.enum';
import LicenseStatusCell from '../../../components/licenses/licenseStatusCell';
import { LicensesService } from '../../../services/licenses.service';
import { LoaDetail } from '../../../types/data/loaDetail.type';
import { PaginatedSuccessResponse } from '../../../types/response/paginatedSuccessResponse.type';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../enums/roles.enum';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import { StateConstants } from '../../../constants/state.constants';
import { cancelPreviousRequest } from '../../../utils/api.utils';
import { isEmpty } from 'lodash';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { useAuth } from '../../../auth/authProvider';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;

interface FilterGroups {
  filterGroups: [];
}

function AllLicenses() {
  const [loading, setIsLoading] = useState<boolean>(false);
  const [activeData, setActiveData] = useState<any>('');
  const { bearerToken } = useAuth();
  const [count, setCount] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [queryText, setQueryText] = useQueryState('search');
  const [errorMessage, setErrorMessage] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sortValue, setSortValue] = useState({});

  let columns: ColumnsType<AllLicenseResponse> = [
    {
      title: 'State',
      dataIndex: 'stateCode',
      key: 'stateCode',
      sorter: false,

      render: (text: string, record: AllLicenseResponse) => (
        <Row align="middle">
          <div>
            <Typography.Paragraph
              style={{
                color: 'var(--secondary-color)',
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              {StateConstants[text]}
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{
                fontSize: '11px',
                fontWeight: 400,
                marginTop: '-10px',
                marginBottom: '0',
              }}
            >
              {record.licenseNumber}
            </Typography.Paragraph>
          </div>
        </Row>
      ),
    },
    {
      title: 'License Status',
      dataIndex: 'status',
      key: 'status',
      sorter: false,

      render: (text: string, record: AllLicenseResponse) => (
        <LicenseStatusCell licenseStatus={text} />
      ),
    },
    {
      title: 'License Class & LOAs',
      dataIndex: 'licenseConfigurations',
      key: 'licenseClass',
      sorter: false,

      render: (loas: LoaDetail[], record: AllLicenseResponse) => (
        <Row align="middle">
          <LcLoaCell
            licenseClass={record?.licenseConfigurations?.licenseClass}
            loaDetails={record?.licenseConfigurations?.loaDetails}
            // requiredStatus={LoaStatus.ACTIVE}
          />
        </Row>
      ),
    },
    {
      title: 'Expiry',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (text: string, record: any) => {
        return (
          <DaysToExpireCell
            expiryDate={text}
            licenseStatus={record?.status as LicenseStatus}
            licenseState={StateConstants[record?.stateCode]}
          />
        );
      },
    },
    {
      title: 'Renewal Type',
      dataIndex: 'producerDetails',
      key: 'autoRenews',
      render: (text: boolean, record: AllLicenseResponse) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            type="secondary"
            style={{
              fontSize: '12px',
              marginBottom: '0.25em',
            }}
          >
            {record?.producerDetails?.paymentConfig?.isAutoRenewalActive ===
            true
              ? 'Auto Apply'
              : 'Does not auto apply'}
          </Typography.Paragraph>
        </Row>
      ),
    },

    {
      title: 'Assigned Status',
      dataIndex: 'licenseConfigurations',
      key: 'isStateAssigned',
      render: (text: boolean, record: any) => ({
        children: record?.licenseConfigurations?.agencyAssignment[0]?.isAssigned
          ? 'Assigned'
          : 'Unassigned',
        props: {
          style: {
            color: record?.licenseConfigurations?.agencyAssignment[0]
              ?.isAssigned
              ? 'green'
              : 'red',
          },
        },
      }),
    },
  ];
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<Object>({});
  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const filterGroups = setFilterGroups({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);

      return updatedFilters;
    });
  };

  const fetchFiltersInfoForFindAgentAllLicense: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      setIsFilterInfoLoading(true);
      if (bearerToken) {
        const response: any =
          await LicensesService.getFilterInfoForFindAgentAllLicenses(
            bearerToken,
            {}
          );
        return response.data.filter((d: any) => d.key !== 'producerIds');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(
        'An error occurred while fetching admins. Please try again later.'
      );
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchLicenseDetails(queryText || '', newPageNumber, newPageSize);
  };

  useEffect(() => {
    fetchLicenseDetails();
  }, [bearerToken, queryText]);

  useEffect(() => {
    if (Object.keys(requestBody).length) {
      setPageNumber(1);
      fetchLicenseDetails('', 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestBody]);

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  const fetchLicenseDetails = async (
    search?: string,
    pageNumberProp?: number,
    pageSizeProp?: number,
    sort?: object
  ) => {
    try {
      if (bearerToken) {
        let defaultRequestBody;
        setIsLoading(true);
        defaultRequestBody = {
          agents: [],
        };

        cancelToken && cancelPreviousRequest(cancelToken);
        const cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource);
        const response: PaginatedSuccessResponse<AllLicenseResponse> =
          await LicensesService.findAllForAgent(
            {
              ...requestBody,
              ...defaultRequestBody,
              ...(sort || sortValue || {}),
            },
            bearerToken,
            {},
            pageSizeProp || pageSize,
            pageNumberProp || pageNumber,
            search || queryText || '',
            cancelTokenSource
          );
        if (response) {
          setCancelToken(undefined);
          setCount(response.totalCount);
          setActiveData(response.data);
          setErrorMessage('');
          setIsLoading(false);
        } else {
          setCount(0);
          setErrorMessage('No producer data available.');
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setCancelToken(undefined);
        setIsLoading(false);
      }
      console.error('Error:', error);
      setErrorMessage(
        'An error occurred while fetching admins. Please try again later.'
      );
    }
  };

  return (
    <Card
      style={{
        display: 'block',
        width: '100%',
      }}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#0588ca',
          },
        }}
      >
        <div style={{ display: 'flex', columnGap: '10px', marginBottom: 10 }}>
          {/* <Search
            id={IdConstants.SEARCH_INPUT + '-all-licenses'}
            size="middle"
            placeholder="Search by State, License Class , LOA"
            style={{
              width: '100%',
            }}
            value={searchText}
            // allowClear
            disabled={loading}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onSearch={(value) => {
              if (queryText !== value) {
                setQueryText(value);
                setPageNumber(1);
              }
            }}
            onPressEnter={(e) => {
              if (queryText !== (e.target as HTMLInputElement).value) {
                setQueryText((e.target as HTMLInputElement).value);
                setPageNumber(1);
              }
            }}
          /> */}
          {/* TODO Change to Filter Button Component */}
          {/* <Button
            id={IdConstants.FILTERS_BUTTON}
            loading={filterInfoLoading}
            icon={<FilterOutlined />}
            style={{
              background: 'var(--hint-primary-color)',
              color: 'white',
            }}
            onClick={() => setIsFilterVisible(true)}
            disabled={
              isDisableFilter(activeData, requestBody) ||
              filterInfoLoading ||
              loading ||
              ((requestBody as FilterGroups)?.filterGroups?.length === 0 &&
                activeData.length === 0)
            }
          >
            Filters
          </Button> */}
        </div>
        <RenderFilterChip
          {...{ selectedFilters, removeFilter, clearFilters }}
        />
        {loading ? (
          <Skeleton active />
        ) : (
          <Table
            columns={columns}
            dataSource={activeData}
            pagination={false}
            onChange={(pagination, filters, sorter) => {
              const sort = applySort(sorter, fetchLicenseDetails);
              setSortValue(sort);
            }}
          />
        )}
        {!isEmpty(activeData) && (
          <Row
            className="pagination"
            style={{ marginTop: '10px' }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={pageNumber}
              pageSize={pageSize}
              onChange={onChange}
              showSizeChanger
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              disabled={loading}
            />
          </Row>
        )}
      </ConfigProvider>

      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        fetchFilters={() => fetchFiltersInfoForFindAgentAllLicense()}
        clearFilters={() => {
          clearFilters();
        }}
      />
    </Card>
  );
}
export default withRoles(AllLicenses, [RoleType.AGENT]);
