import {
  Card,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Spin,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../../types/common/filters.type';
import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  InsuranceLayout,
  Insurancetypes,
  TextDetail,
} from '../../../../utils/modal/application-status/applicationstatus.style';
import {
  UnresolvedTooltip,
  formatDateWithTime,
} from '../../../../utils/date.utils';
import {
  applySort,
  getStateNameFromCode,
} from '../../../../utils/common.utils';
import { useEffect, useState } from 'react';

import ApplicationStatusCell from '../../../../components/licenses/applicationStatusCell';
import { ApplicationStatusEnum } from '../../../../enums/applicationStatus.enum';
import ApplicationTypeCell from '../../../../components/applications/applicationTypeCell';
import { ColumnsType } from 'antd/es/table';
import FeesCell from '../../../../components/applications/feesCell';
import FilterButton from '../../../../components/filterButton';
import { IdConstants } from '../../../../constants/id.constants';
import { LicenseApplicationResponse } from '../../../../types/response/license-application.type';
import LicenseModal from '../../../../utils/modal/application-status/applictionStatus.modal';
import { LicensesService } from '../../../../services/licenses.service';
import { PaginatedSuccessResponse } from '../../../../types/response/paginatedSuccessResponse.type';
import { PlausiblePageConstants } from '../../../../constants/page.constants';
import { PortalType } from '../../../../enums/portalType.enum';
import { RenderFilterChip } from '../../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../../enums/roles.enum';
import SimpleFilter from '../../../../components/common/simpleFilter/simpleFilter';
import StateCell from '../../../../components/licenses/stateCell';
import { adminStore } from '../../../../stores/admin.store';
import axios from 'axios';
import { getCostFromTransaction } from '../../../../utils/nipr/license.utils';
import { getTimeDifference } from '../../../../utils/date/date-difference';
import { isEmpty } from 'lodash';
import { setFilterGroups } from '../../../../utils/setSimpleFiltersRequest.utils';
import { useAuth } from '../../../../auth/authProvider';
import { useQueryState } from '../../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../../auth/useRoles';

const { Search } = Input;
// Pending Agency Applications
function PendingAgencyApplications() {
  const [activeData, setActiveData] = useState<LicenseApplicationResponse[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState<string>('');
  const [queryText, setQueryText] = useQueryState('search');

  const [applicationModalVisible, setApplicationModalVisible] =
    useState<boolean>(false);
  const [selectedLicenseDetails, setSelectedLicenseDetails] =
    useState<LicenseApplicationResponse>();
  const [autoRenewEnabled, setAutoRenewEnabled] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean | string>(false);

  // Adding Filters
  const [requestBody, setRequestBody] = useState<Object>({});
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [sortValue, setSortValue] = useState({});

  useEffect(() => {
    fetchLicenses();
  }, [adminStore.account, queryText]);

  const fetchFiltersInfoForFindAll: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      setIsFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any =
          await LicensesService.getFilterInfoForFindAgencyApplications(
            token,
            requestBody
          );
        return response.data;
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  const fetchLicenses = async (
    searchTextProp?: string,
    page?: number,
    size?: number,
    sort?: object
  ) => {
    if (!adminStore.agency?.id) return;
    try {
      setIsLoading(true);
      const defaultRequestBody = {
        agency: adminStore.agency?.id,
        // showOnlyAssignedStates: true,
      };
      const token = await getAccessTokenSilently();
      const response: PaginatedSuccessResponse<LicenseApplicationResponse> | void =
        await LicensesService.getAgencyApplicationsForCurrentAgency(
          {
            ...requestBody,
            ...defaultRequestBody,
            ...(sort || sortValue || {}),
          },
          token,
          size || pageSize,
          page || pageNumber,
          searchTextProp ? searchTextProp : queryText || ''
        );
      if (response) {
        setTotalCount(response.totalCount);
        setActiveData(
          response.data.map((application) => ({
            ...application,
            cost: getCostFromTransaction(application?.gatewayTransaction),
            licenseClass: application.licenseConfigurations?.[0]?.licenseClass,
            licenseClassCode:
              application.licenseConfigurations?.[0]?.licenseClassCode,
          }))
        );
        setIsLoading(false);
      } else {
        setTotalCount(0);
        setActiveData([]);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
      }
      console.error('Error:', error);
    }
  };

  const handleRowClick = (record: LicenseApplicationResponse) => {
    setSelectedLicenseDetails(record);
    setAutoRenewEnabled(record.agent?.autoRenewEnabled);
    setApplicationModalVisible(true); // Open the modal
    setApplicationModalVisible(true);
  };

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  const onPaginationChange: PaginationProps['onChange'] = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchLicenses(queryText, newPageNumber, newPageSize);
  };

  const refreshPoll = async (id?: string) => {
    const token = await getAccessTokenSilently();
    setIsRefreshing(id || true);
    try {
      if (id) await LicensesService.getPollingStatusById(id, token);
      else await LicensesService.pollAgencyFirmApplications(token);
      message.success(
        'Polling initiated successfully, please refresh in sometime'
      );
      fetchLicenses();
    } catch (err) {
      message.error('Polling refresh failed. Please try again.');
    } finally {
      setIsRefreshing(false);
    }
  };

  const Applicationcolumns: ColumnsType<LicenseApplicationResponse> = [
    {
      title: 'State',
      dataIndex: 'state',
      sorter: false,
      key: 'license.stateName',
      render: (_: any, record: LicenseApplicationResponse) => (
        <StateCell
          state={getStateNameFromCode(record.license.stateCode)}
          hidePerpetualTag={true}
        />
      ),
    },
    {
      title: 'License Class & LOAs',
      dataIndex: 'licenseClass',
      key: 'license.licenseClass',
      sorter: false,
      render: (_: any, record: LicenseApplicationResponse) => {
        return (
          <>
            <TextDetail fontSize={'12px'}>
              {(record as any)?.licenseConfigurations?.[0]?.licenseClass}
            </TextDetail>
            <Insurancetypes>
              {(record as any)?.licenseConfigurations?.map(
                (loa: any, index: number) => (
                  <InsuranceLayout key={index}>
                    <TextDetail
                      style={{ textAlign: 'center' }}
                      fontSize={'12px'}
                    >
                      {loa?.loa}
                    </TextDetail>
                  </InsuranceLayout>
                )
              )}
            </Insurancetypes>
          </>
        );
      },
    },
    {
      title: 'Fee',
      dataIndex: 'latestApplication',
      key: 'cost',
      // sorter: false,
      width: '100px',
      render: (_: any, record: LicenseApplicationResponse) =>
        record?.cost ? <FeesCell fees={record.cost} isEstimate={false} /> : '-',
    },
    {
      title: 'Application Type',
      dataIndex: 'latestApplication',
      key: 'applicationType',
      render: (_: any, record: LicenseApplicationResponse) => (
        <ApplicationTypeCell applicationType={record?.applicationType} />
      ),
    },
    {
      title: 'Applied on',
      sorter: false,
      dataIndex: 'latestApplication',
      key: 'applyDate',
      render: (_: any, record: LicenseApplicationResponse) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            style={{
              fontSize: '12px',
              fontWeight: 400,
              whiteSpace: 'nowrap',
              marginBottom: 0,
            }}
          >
            {record?.applyDate ? (
              <Tooltip title={formatDateWithTime(record.applyDate)}>
                {getTimeDifference(record.applyDate.toString())}
              </Tooltip>
            ) : (
              '-'
            )}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      // title: 'Polled On',
      title: (
        <p style={{ display: 'inline-flex', gap: 12, alignItems: 'center' }}>
          Polled On{' '}
          <Tooltip
            title={isRefreshing === true ? 'Refreshing...' : 'Refresh Poll'}
          >
            {isRefreshing === true ? (
              <Spin size="small" />
            ) : (
              <ReloadOutlined
                style={{ fontSize: 14, color: '#0588ca' }}
                onClick={() => refreshPoll()}
                className="cursor-pointer primary-color"
              />
            )}
          </Tooltip>
        </p>
      ),
      width: 180,
      dataIndex: 'latestApplication',
      key: 'latestApplication',
      render: (_: any, record: LicenseApplicationResponse) => {
        const lastPolledDate = record.lastPolledDate;
        return (
          <Row align="middle" style={{ textAlign: 'center' }}>
            <Typography.Paragraph
              style={{
                fontSize: '12px',
                fontWeight: 400,
                whiteSpace: 'nowrap',
                marginBottom: 0,
              }}
            >
              {record.gatewayTransaction?.submissionSuccess ? (
                lastPolledDate ? (
                  <Tooltip title={formatDateWithTime(new Date(lastPolledDate))}>
                    {getTimeDifference(lastPolledDate)}
                  </Tooltip>
                ) : (
                  ' NIPR polling not initiated'
                )
              ) : (
                <>
                  {record?.status === ApplicationStatusEnum.Declined ? (
                    'Polling Complete'
                  ) : (
                    <p
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: 12,
                      }}
                    >
                      <Tooltip title={UnresolvedTooltip()}>
                        NIPR polling not initiated{' '}
                      </Tooltip>
                    </p>
                  )}
                </>
              )}
            </Typography.Paragraph>
          </Row>
        );
      },
    },
    {
      title: 'Application Status',
      dataIndex: 'latestApplication',
      key: 'status',
      sorter: false,
      width: '175px',
      align: 'center',
      fixed: 'right',
      render: (_: any, record: LicenseApplicationResponse) => {
        return (
          <Row
            id={
              IdConstants.LICENSES.APPLICATIONS.TABLE.VIEW_DETAILS +
              '-' +
              record.license.licenseNumber
            }
            align="middle"
            style={{
              textAlign: 'center',
            }}
            onClick={() => {
              if (record?.status !== ApplicationStatusEnum.Not_Applied)
                handleRowClick(record);
            }}
          >
            <ApplicationStatusCell applicationStatus={record?.status} />
          </Row>
        );
      },
    },
  ];

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups: [],
    };
    setRequestBody(updatedRequestBody);
  };

  useEffect(() => {
    if (Object.keys(requestBody).length) fetchLicenses('', 1);
    setPageNumber(1);
  }, [requestBody]);
  const pageName = PlausiblePageConstants.AGENCY_PENDING_APPLICATION;
  return (
    <Card
      style={{
        width: '100%',
        height: 'fit-content',
      }}
    >
      <div style={{ marginBottom: 10 }} className="reset-icon-size">
        <Typography.Text type="secondary" style={{ fontSize: 14 }}>
          <InfoCircleOutlined />{' '}
          <span>
            Review all submitted license applications awaiting NIPR approval.
            Use the poll option to receive real-time updates from NIPR on these
            applications.
          </span>
        </Typography.Text>
      </div>
      <div style={{ display: 'flex', columnGap: '10px', marginBottom: '10px' }}>
        {/* <Search
          id={IdConstants.SEARCH_INPUT + '-licenses-applications'}
          placeholder="Search by Producer, State"
          style={{
            width: '100%',
          }}
          value={searchText}
          // allowClear
          disabled={isLoading}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onSearch={(value) => {
            if (queryText !== value) {
              setQueryText(value);
              setPageNumber(1);
            }
          }}
          onPressEnter={(e) => {
            if (queryText !== (e.target as HTMLInputElement).value) {
              setQueryText((e.target as HTMLInputElement).value);
              setPageNumber(1);
            }
          }}
        /> */}
        <FilterButton
          {...{
            filterInfoLoading,
            activeData,
            requestBody,
            isLoading,
            setIsFilterVisible,
            pageName,
          }}
        />
      </div>
      <RenderFilterChip
        {...{ selectedFilters, removeFilter, clearFilters, pageName }}
      />
      <div
        className="reset-icon-size"
        style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}
      >
        <Table
          columns={Applicationcolumns}
          dataSource={activeData}
          pagination={false}
          onChange={(pagination, filters, sorter) => {
            const sort = applySort(sorter, fetchLicenses);
            setSortValue(sort);
          }}
          loading={isLoading}
        />
        {!isEmpty(activeData) && (
          <Row justify="end">
            <Pagination
              showSizeChanger
              pageSize={pageSize}
              current={pageNumber}
              onChange={onPaginationChange}
              total={totalCount}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              disabled={isLoading}
            />
          </Row>
        )}
      </div>
      <LicenseModal
        visible={applicationModalVisible}
        setVisible={setApplicationModalVisible}
        agentLicenseDetails={selectedLicenseDetails}
        portal={PortalType.AGENCY}
        autoRenewEnabled={autoRenewEnabled}
        showTransactionDetails={true}
        hideProducerName={true}
      />
      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        fetchFilters={() => fetchFiltersInfoForFindAll()}
        clearFilters={() => {
          clearFilters();
        }}
        pageName={pageName}
      />
    </Card>
  );
}
export default withRoles(PendingAgencyApplications, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
