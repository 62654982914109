import { useEffect, useState } from 'react';

import AllLicensesAgencyTab from './agency/allLicensesAgencyTab';
import AllLicensesAgentsTab from './all-licenses-agents-tab';
import { RoleType } from '../../../../enums/roles.enum';
import TabView from '../../../../components/common/tabview/tabview';
import { useSearchParams } from 'react-router-dom';
import { withRoles } from '../../../../auth/useRoles';

function AllLicensesTabView() {
  return (
    <div style={{ width: '100%' }} className="onboard-active">
      {<AllLicensesAgentsTab />}
    </div>
  );
}

export default withRoles(AllLicensesTabView, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
