import { Row, Tooltip, Typography } from 'antd';

import { LicenseStatus } from '../../enums/licenseStatus.enum';
import { PERPETUAL_STATES } from '../../constants/perpetualStates.constants';
import { capitalizeFirstLetter } from '../../utils/common.utils';
import { isEmpty } from 'lodash';
import moment from 'moment';

function DaysToExpireCell({
  expiryDate,
  licenseStatus,
  licenseState,
}: {
  expiryDate: string;
  licenseStatus: LicenseStatus;
  licenseState: string;
}) {
  const expiryDateMomentObj = moment(expiryDate);
  return (
    <Row align="middle" style={{ textAlign: 'center' }}>
      {expiryDate && licenseStatus === LicenseStatus.Active ? (
        <Tooltip title={'Expires on ' + expiryDateMomentObj.format('ll')}>
          <Typography.Paragraph
            style={{
              fontSize: '14px',
              marginBottom: '0.25em',
            }}
          >
            {capitalizeFirstLetter(expiryDateMomentObj.fromNow())}
          </Typography.Paragraph>
        </Tooltip>
      ) : (
        <Typography.Paragraph
          type="secondary"
          style={{
            fontSize: '12px',
            marginBottom: '0.25em',
          }}
        >
          {licenseStatus !== LicenseStatus.Active && 'License is not active'}
          {licenseStatus === LicenseStatus.Active &&
            isEmpty(expiryDate) &&
            PERPETUAL_STATES.includes(licenseState) &&
            'does not expire'}
          {licenseStatus === LicenseStatus.Active &&
            isEmpty(expiryDate) &&
            !PERPETUAL_STATES.includes(licenseState) &&
            'No expiry set'}
        </Typography.Paragraph>
      )}
    </Row>
  );
}

export default DaysToExpireCell;
