import {
  AccountLink,
  StripeAccountDetailsDto,
} from '../types/data/stripe/stripeAccount.type';
import {
  LicenseConfigOnboarding,
  StateGroup,
  StateGroupIndividualOnboarding,
  UpdatedStateGroup,
  licenseConfigurationsByState,
} from '../types/data/stateGroup.types';
import {
  UpdateProducerDTO,
  updateProducerAssignmetPayload,
} from '../pages/agents/onboardAgents/individualOnboardV2/individualOnboardV2Type.type';

import { ArraySuccessResponse } from '../types/response/arraySuccessResponse.type';
import { ErrorResponse } from '../types/response/errorResponse.type';
import { NiprLedger } from '../types/data/ledger.type';
import { ObjectSuccessResponse } from '../types/response/objectSuccessResponse.type';
import { PaginatedSuccessResponse } from '../types/response/paginatedSuccessResponse.type';
import { ResponseStatus } from '../enums/responseStatus.enum';
import { StripeBalance } from '../types/data/stripe/stripeBalance.type';
import { UrlConstants } from '../constants/url.constants';
import { VerifyAgencyAndOnboard } from '../types/request/agency/verifyAndOnboard';
import { apiStore } from '../stores/api.store';
import axios from 'axios';
import { cancelPreviousRequest } from '../utils/api.utils';
import { createDuplicateStateGroup } from '../types/request/agency/stateGroups/createDuplicateStateGroupRequest.type';
import { handleBeError } from '../utils/errorUtils';
import { message } from 'antd';

export class AgencyService {
  static host = UrlConstants.backend;
  static modulePath = '/agency';

  /**
   * Retrieve my agency info for onboarding agent
   * @param accessToken
   * @returns
   */
  static async getMyAgencyInfo(
    accessToken: string
  ): Promise<ObjectSuccessResponse<any>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(accessToken)
          .get(`${this.host}/agency`);
        resolve(response.data as ObjectSuccessResponse<any>);
      } catch (error: any) {
        console.error(
          'AgencyService :: getMyAgencyInfo :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Retrieve territory by name
   * @param accessToken
   * @returns
   */
  static async getStateGroupByName(
    stateGroupName: string,
    accessToken: string
  ): Promise<ObjectSuccessResponse<undefined>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(accessToken)
          .get(
            `${this.host}${this.modulePath}/my-agency/state-groups/name/${stateGroupName}`
          );
        resolve(response.data as ObjectSuccessResponse<undefined>);
      } catch (error: any) {
        console.error(
          'AgencyService :: getMyAgencyInfo :: Error :: ',
          error?.message
        );
        reject(handleBeError(error));
      }
    });
  }

  /**
   * get territories of the agencies
   * /agents
   * @param producerId
   * @param bearerToken
   * @returns
   */
  static async getTerritories(
    bearerToken: string
  ): Promise<ObjectSuccessResponse<StateGroupIndividualOnboarding[]>> {
    return new Promise(async (resolve, reject) => {
      const path = `/agency/territories`;

      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`);

        resolve(
          response.data as ObjectSuccessResponse<
            StateGroupIndividualOnboarding[]
          >
        );
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * update  producer details
   * /agencies
   * @param producerId
   * @param bearerToken
   * @returns
   */
  static async updateProducerDetails(
    producerId: string,
    payload: object,
    bearerToken: string
  ): Promise<ObjectSuccessResponse<UpdateProducerDTO>> {
    return new Promise(async (resolve, reject) => {
      const path = `/account/producer/${producerId}/onboard`;

      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .patch(`${this.host}${path}`, payload);
        resolve(response.data as ObjectSuccessResponse<any>);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * update  assignment for the producer details
   * /agencies
   * @param producerId
   * @param bearerToken
   * @returns
   */
  static async updateAssignmentsForProducer(
    producerId: string,
    payload: updateProducerAssignmetPayload[],
    bearerToken: string
  ): Promise<ObjectSuccessResponse<any>> {
    return new Promise(async (resolve, reject) => {
      const path = `/account/producer/${producerId}/assign-states`;

      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .patch(`${this.host}${path}`, payload);
        resolve(response.data as ObjectSuccessResponse<any>);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * update  assignment for the producer details
   * /agencies
   * @param producerId
   * @param bearerToken
   * @returns
   */
  static async updateBulkAssignmentsForProducer(
    producerIds: string[],
    producerStateAssignments: updateProducerAssignmetPayload[],
    bearerToken: string
  ): Promise<ObjectSuccessResponse<any>> {
    return new Promise(async (resolve, reject) => {
      const path = `/account/producer/assign-states`;
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${path}`, {
            producerIds,
            producerStateAssignments,
          });
        resolve(response.data as ObjectSuccessResponse<any>);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Retrieves list of created territories of an agency
   * @param accessToken
   * @param pageNumber
   * @param pageSize
   * @param searchString
   * @returns
   */
  static async getStateGroupsForAgency(
    accessToken: string,
    pageNumber?: number,
    pageSize?: number,
    searchString?: string
  ): Promise<
    | PaginatedSuccessResponse<UpdatedStateGroup>
    | ArraySuccessResponse<UpdatedStateGroup>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        cancelPreviousRequest(apiStore.getTokenForGetStateGroupsForAgency());
        const cancelTokenSource = axios.CancelToken.source();
        apiStore.setTokenForGetStateGroupsForAgency(cancelTokenSource);

        let url_ = new URL(`${this.host}/agency/territories`);
        if (pageNumber) url_.searchParams.append('page', pageNumber.toString());
        if (pageSize) url_.searchParams.append('size', pageSize.toString());
        if (searchString) {
          url_.searchParams.append(
            'searchString',
            encodeURIComponent(searchString)
          );
        }

        const response = await apiStore
          .getApiClient(accessToken, cancelTokenSource)
          .get(`${url_}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });
        resolve(
          response.data as unknown as
            | PaginatedSuccessResponse<UpdatedStateGroup>
            | ArraySuccessResponse<UpdatedStateGroup>
        );
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * Updates agency's general details
   * @param id
   * @param data
   * @param accesstoken
   * @returns
   */
  static async updateAgencydetails(
    data: any,
    accesstoken: string
  ): Promise<
    PaginatedSuccessResponse<StateGroup> | ArraySuccessResponse<StateGroup>
  > {
    return new Promise(async (resolve, reject) => {
      const apiPath = '/agency';
      for (const key in data.address) {
        if (data.address.hasOwnProperty(key)) {
          delete data.address[key]._id;
        }
      }
      try {
        const response = await apiStore.getApiClient(accesstoken).patch(
          `${this.host}${apiPath}`,
          {
            phone: data.phone,
            email: data.email,
            fax: data.fax,
            stateCode: data.stateCode,
            stateAddress: Object.values(data.address),
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
        resolve(response.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * Deletes agency account
   * @param id
   * @param accesstoken
   * @returns
   */
  static async deleteAgency(
    accesstoken: string
  ): Promise<
    PaginatedSuccessResponse<StateGroup> | ArraySuccessResponse<StateGroup>
  > {
    return new Promise(async (resolve, reject) => {
      const apiPath = '/agencies/my-agency/';

      try {
        const response = await apiStore
          .getApiClient(accesstoken)
          .delete(`${this.host}${apiPath}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });
        resolve(response.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * Retrieves territory's information for a given territory ID
   * @param stateGroupId
   * @param token
   * @returns
   */
  static async findStateGroupById(
    stateGroupId: string,
    token: string
  ): Promise<ObjectSuccessResponse<StateGroup>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(`${this.host}/agency/territory/${stateGroupId}`);

        const filteredStateGroup = response?.data?.data.filter(
          (d: StateGroupIndividualOnboarding) => d.id === stateGroupId
        )?.[0] as StateGroupIndividualOnboarding;

        resolve({
          status: ResponseStatus.SUCCESS,
          data: {
            name: filteredStateGroup.name,
            id: filteredStateGroup.id,
            stateLicenseConfigs: filteredStateGroup.stateLicenseConfigs.map(
              (d: any) => {
                return {
                  stateCode: d.stateCode,
                  licenseConfigs: d.licenseConfigs.map(
                    (data: LicenseConfigOnboarding) => {
                      return {
                        lcCode: data.lcCode,
                        loaCodes: data.loaConfigs.map((d) => d.loaCode),
                        loaids: data.loaConfigs.map((d) => d.id || ''),
                      };
                    }
                  ),
                };
              }
            ),
          },
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Deletes territory for a given territory ID
   * @param stateGroupId
   * @param token
   * @returns
   */
  static async deleteStateGroupById(
    stateGroupId: string,
    deleteAssignments: boolean,
    token: string
  ): Promise<ObjectSuccessResponse<StateGroup>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .delete(`${this.host}/agency/territory/${stateGroupId}`, {
            data: {
              deleteAssignments,
            },
          });

        resolve(response.data as ObjectSuccessResponse<StateGroup>);
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Updates territory for a given territory ID
   * @param stateGroup
   * @param token
   * @returns
   */
  static async updateStateGroupById(
    territoryId: string,
    territoryName: string,
    stateLicenseConfigs: licenseConfigurationsByState[],
    deleteOldAssignments: boolean,
    token: string
  ): Promise<ObjectSuccessResponse<StateGroup>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .patch(`${this.host}/agency/territory/${territoryId}`, {
            name: territoryName,
            licenseConfigurationsGroupedByState: stateLicenseConfigs,
          });

        resolve(response.data as ObjectSuccessResponse<StateGroup>);
      } catch (err: any) {
        reject(err.response.data);
      }
    });
  }

  /**
   * Creates a new territory for the given agency
   * @param stateGroupName
   * @param stateLicenseConfigs
   * @param token
   * @returns
   */
  static async createStateGroup(
    stateGroupName: string,
    stateLicenseConfigs: licenseConfigurationsByState[],
    token: string
  ): Promise<ObjectSuccessResponse<StateGroup>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(`${this.host}/agency/territory`, {
            name: stateGroupName,
            licenseConfigurationsGroupedByState: stateLicenseConfigs,
          });

        resolve(response.data as ObjectSuccessResponse<StateGroup>);
      } catch (err: any) {
        reject(err.response.data);
      }
    });
  }

  static async createDuplicateStateGroup(
    stateGroupId: string,
    createStateGroupDto: createDuplicateStateGroup,
    token: string
  ): Promise<ObjectSuccessResponse<StateGroup>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .patch(
            `${this.host}${this.modulePath}/my-agency/state-groups/${stateGroupId}/duplicate`,
            createStateGroupDto
          );

        resolve(response.data as ObjectSuccessResponse<StateGroup>);
      } catch (err: any) {
        reject(err.response.data as ErrorResponse);
      }
    });
  }

  /**
   * Verifies agency information during admin signup and onboards admin
   * @param verifyAgencyAndOnboard
   * @param token
   * @returns
   */
  static async verifyAgencyDetailsAndOnboard(
    verifyAgencyAndOnboard: VerifyAgencyAndOnboard,
    token: string
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const agency = await apiStore.getApiClient(token).post(
          `${this.host}/agencies/verify-agency-and-onboard`,

          verifyAgencyAndOnboard
        );
        resolve(agency);
      } catch (error: any) {
        console.error('AgencyService :: getAgency :: Error :: ', error);
        reject(error.response.data as ErrorResponse);
      }
    });
  }

  /**
   * Bulk updates agents information (contact information)
   * @param data
   * @param accesstoken
   * @returns
   */
  static async UpdateBulkAgentDetails(
    data: any,
    accesstoken: string,
    isSelectAll: boolean
  ) {
    return new Promise(async (resolve, reject) => {
      const apiPath = '/agencies';

      try {
        const response: any = await apiStore.getApiClient(accesstoken).patch(
          `${this.host}${apiPath}/my-agency/agency-info-to-agents`,
          {
            ...data,
            ...(isSelectAll ? { isSelectAll } : {}),
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

        resolve(response.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * Fetches the balance for the current agency from stripe
   * @param bearerToken
   */
  static async getBalanceForCurrentAgency(
    token: string
  ): Promise<ObjectSuccessResponse<StripeBalance>> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.host}${this.modulePath}/my-agency/balance`;
        const response = await apiStore.getApiClient(token).get(url);

        resolve(response.data as ObjectSuccessResponse<StripeBalance>);
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Fetches the stripe details for the current agency
   * @param bearerToken
   */
  static async getStripeDetailsForCurrentAgency(
    token: string
  ): Promise<ObjectSuccessResponse<StripeAccountDetailsDto>> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.host}${this.modulePath}/my-agency/stripe`;
        const response = await apiStore.getApiClient(token).get(url);

        resolve(
          response.data as ObjectSuccessResponse<StripeAccountDetailsDto>
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Checks if the agency has been onboarded on stripe for the current agency
   * @param bearerToken
   */
  static async refreshIsOnboardedForCurrentAgency(
    token: string
  ): Promise<ObjectSuccessResponse<boolean>> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this.host}${this.modulePath}/my-agency/refresh-stripe-is-onboarded`;
        const response = await apiStore.getApiClient(token).get(url);

        resolve(response?.data as ObjectSuccessResponse<boolean>);
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Fetches a new stripe account link for the current agency
   * @param bearerToken
   */
  static async refreshStripeOnboardingLinkForCurrentAgency(
    token: string
  ): Promise<ObjectSuccessResponse<AccountLink>> {
    try {
      const url = `${this.host}${this.modulePath}/my-agency/refresh-stripe-onboarding-link`;
      const response = await apiStore.getApiClient(token).get(url);

      return response.data as ObjectSuccessResponse<AccountLink>;
    } catch (error) {
      console.error('error : ', error);
      throw error;
    }
  }

  /**
   * Creates a payment link for a topup
   */
  static async createTopupPaymentLinkForCurrentAgency(
    amount: number,
    token: string
  ): Promise<ObjectSuccessResponse<string>> {
    try {
      const url = `${this.host}${this.modulePath}/my-agency/topup-payment-link?amount=${amount}`;
      const response = await apiStore.getApiClient(token).post(url);

      return response.data as ObjectSuccessResponse<string>;
    } catch (error: any) {
      console.error('error from service : ', error);
      throw error?.response?.data as ErrorResponse;
    }
  }

  /**
   * Fetches topup history for the current agency
   * @param bearerToken
   */
  static async getTopupHistoryForCurrentAgency(
    page: string | undefined,
    token: string
  ): Promise<ObjectSuccessResponse<any>> {
    try {
      const url = `${this.host}${this.modulePath}/my-agency/topup-history${
        page ? `?page=${page}` : ' '
      }`;
      const response = await apiStore.getApiClient(token).get(url);

      return response.data as ObjectSuccessResponse<any>;
    } catch (error) {
      console.error('error : ', error);
      throw error;
    }
  }

  /**
   * Retrieves background question answers for an agent
   * /agencies/{agentId}/background-questions-support-documents
   * @param bearerToken
   * @returns
   */
  static async getOnboardedAgentBackgroundQuestionsForAgency(
    bearerToken: string,
    agentId?: string
  ) {
    const path = `/account/producer/${agentId}/bg-question/supporting-docs`;
    return new Promise(async (resolve, reject) => {
      try {
        const backgroundQuestionsAnswers = await apiStore
          .getApiClient(bearerToken)
          .get(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(backgroundQuestionsAnswers.data);
      } catch (error: any) {
        console.error('AgencyService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  static async getNiprLedger(
    pageSize: number,
    pageNumber: number,
    bearerToken: string,
    searchText: string,
    requestBody: Object = {}
  ): Promise<PaginatedSuccessResponse<NiprLedger>> {
    try {
      const url = `${this.host}${
        this.modulePath
      }/my-agency/nipr-ledger?page=${pageNumber}&size=${pageSize}${
        searchText && `&searchString=${encodeURIComponent(searchText)}`
      }`;

      const response = await apiStore
        .getApiClient(bearerToken)
        .post(url, requestBody, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

      return response.data as PaginatedSuccessResponse<NiprLedger>;
    } catch (error: any) {
      console.error('AgencyService Error :: ', error?.message);
      throw error.response.data as ErrorResponse;
    }
  }

  static async getFilterInfoForNiprLedgerTransactions(
    bearerToken: string,
    requestBody: Object = {}
  ): Promise<any> {
    const url = `${this.host}${this.modulePath}/my-agency/nipr-ledger/filter-options`;
    return new Promise(async (resolve, reject) => {
      try {
        const filterInfo = await apiStore
          .getApiClient(bearerToken)
          .post(url, requestBody);
        resolve(filterInfo.data);
      } catch (error: any) {
        console.error(
          'Agencyservice :: getFilterInfoForNiprLedgerTransactions :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Refresh Agency License Records
   */
  static async refreshAgencyLicensesForCurrentAgency(token: string) {
    try {
      const url = `${this.host}${this.modulePath}/my-agency/refresh-licenses`;
      const response = await apiStore.getApiClient(token).get(url);
      message.success(`Agency License Details Refreshed Successfully`);
      return response.data.data?.licensesLastUpdatedAt;
    } catch (error: any) {
      console.error('error from service : ', error);
      if (error?.response?.data?.error?.message === 'Insufficient Funds') {
        message.error(
          `Unable to refresh licenses due to insufficient funds in your account. Please ensure sufficient funds and try again.`
        );
      } else {
        message.error(
          `Unable to complete the action. An unexpected error occurred. Please try again later.`
        );
      }
    }
  }

  static async getAgencyStateAssignments(token: string) {
    try {
      const url = `${this.host}${this.modulePath}/agency-state-assignments`;
      const response = await apiStore.getApiClient(token).get(url);
      message.success(`Agency State Assignments Fetched Successfully`);
      return response.data.data;
    } catch (error: any) {
      console.error('Error from service:', error);
      if (error?.response?.data?.error?.message === 'Insufficient Funds') {
        message.error(
          `Unable to fetch state assignments due to insufficient funds in your account. Please ensure sufficient funds and try again.`
        );
      } else {
        message.error(
          `Unable to complete the action. An unexpected error occurred. Please try again later.`
        );
      }
    }
  }

  static async manageAgencyStateAssignment(token: string, reqBody: object) {
    const url = `${this.host}${this.modulePath}/manage-states`;
    const response = await apiStore.getApiClient(token).post(url, reqBody);
    message.success(
      'Agency state assignments were updated for the agency successfully'
    );
    return response?.data;
  }

  /**
   * Service to get ownership details of the agency
   * @param token auth0 jwt
   * @returns
   */
  static async getOwnershipDetails(token: string) {
    return new Promise(async (resolve, reject) => {
      const path = `/owner/all`;
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(`${this.host}${path}`);
        resolve(response.data.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * Service to update ownership details of the agency
   * @param token auth0 jwt
   * @returns
   */
  static async postOwnershipDetails(token: string, details: object) {
    return new Promise(async (resolve, reject) => {
      const path = `/owner`;
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(`${this.host}${path}`, details);
        resolve(response.data.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * Service to update ownership details of the agency
   * @param token auth0 jwt
   * @returns
   */
  static async bulkPostOwnershipDetails(token: string, details: object[]) {
    return new Promise(async (resolve, reject) => {
      const path = `/owner/bulk`;
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(`${this.host}${path}`, details);
        resolve(response.data.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * Service to delete ownership details of the agency
   * @param token auth0 jwt
   * @returns
   */
  static async deleteOwnershipDetails(token: string, ownerId: string) {
    return new Promise(async (resolve, reject) => {
      const path = `/owner/${ownerId}`;
      try {
        const response = await apiStore
          .getApiClient(token)
          .delete(`${this.host}${path}`);
        resolve(response.data.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * Service to edit ownership details of the agency
   * @param token auth0 jwt
   * @returns
   */
  static async editOwnershipDetails(
    token: string,
    ownerId: string,
    details: object
  ) {
    return new Promise(async (resolve, reject) => {
      const path = `/owner/${ownerId}`;
      try {
        const response = await apiStore
          .getApiClient(token)
          .patch(`${this.host}${path}`, details);
        resolve(response.data.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  static async calculateFee(token: string, feesCalculation: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const path = `/agency/calculate-fee`;
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(`${this.host}${path}`, feesCalculation);
        resolve(response.data.data as any);
      } catch (error: any) {
        reject(error);
      }
    });
  }
}
