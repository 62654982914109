import {
  Card,
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Row,
} from 'antd';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../types/common/filters.type';
import Table, { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../services/agency.service';
import FilterButton from '../../components/filterButton';
import { NiprLedger } from '../../types/data/ledger.type';
import { PlausiblePageConstants } from '../../constants/page.constants';
import { RenderFilterChip } from '../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { ResponseStatus } from '../../enums/responseStatus.enum';
import SimpleFilter from '../../components/common/simpleFilter/simpleFilter';
import { applySort } from '../../utils/common.utils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { niprEventSubtype } from '../../enums/nipr/niprSubEventType.enum';
import { observer } from 'mobx-react-lite';
import { setFilterGroups } from '../../utils/setSimpleFiltersRequest.utils';
import { useAuth } from '../../auth/authProvider';
import { useQueryState } from '../../utils/sync-query-param/use-query-state';

const { Search } = Input;

function NiprCost() {
  const [niprTransaction, setNiprTransaction] = useState<NiprLedger[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [queryText, setQueryText] = useQueryState('search');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const { getAccessTokenSilently } = useAuth();

  //filters
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<Object>({});
  const [sortValue, setSortValue] = useState({});

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);

      return updatedFilters;
    });
  };

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const filterGroups = setFilterGroups({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  useEffect(() => {
    if (Object.keys(requestBody).length) fetchTransaction(1);
    setPageNumber(1);
  }, [requestBody]);

  const fetchFiltersInfoForNIPRTransaction: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      setIsFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any =
          await AgencyService.getFilterInfoForNiprLedgerTransactions(token);
        return response.data;
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
    fetchTransaction();
  }, [queryText]);

  const fetchTransaction = async (
    pageNumberProp?: number,
    pageSizeProp?: number,
    searchTextProp?: string,
    sort?: object
  ) => {
    setIsLoading(true);
    const bearerToken = await getAccessTokenSilently();
    const resp = await AgencyService.getNiprLedger(
      pageSizeProp || pageSize,
      pageNumberProp || pageNumber,
      bearerToken,
      searchTextProp ? searchTextProp : queryText || '',
      { ...requestBody, ...(sort || sortValue || {}) }
    ).catch((e) => {
      console.error(e);
    });

    if (resp && resp.status === ResponseStatus.SUCCESS) {
      setNiprTransaction(resp.data);
      setTotalCount(resp.totalCount);
    }
    setIsLoading(false);
  };

  const NiprTransactionTable: ColumnsType<NiprLedger> = [
    {
      title: 'Event',
      dataIndex: 'appEventSubType',
      key: 'appEventSubType',
      sorter: false,
      render: (text) => eventTypeLabels[text],
    },
    {
      title: 'NPN',
      dataIndex: 'additionalMetadata',
      key: 'additionalMetadata.npn',
      sorter: false,
      render: (field: any, record) => field?.npn || '-',
    },
    {
      title: 'NIPR Fee',
      dataIndex: 'niprCost',
      sorter: false,
      key: 'niprCost',
      render: (text) => (text ? `$ ${text}` : '-'),
    },
    {
      title: 'Admin',
      dataIndex: ['admin', 'firstName'],
      key: 'admin.firstName',
      sorter: false,
      render: (text, record) => {
        return record.admin
          ? `${record.admin.firstName} ${record.admin.lastName}`
          : '-';
      },
    },
    {
      title: 'Time of Transaction',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: false,
      render: (text, record) => moment(record.createdAt).format('lll'),
    },
  ];

  const onPaginationChange: PaginationProps['onChange'] = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchTransaction(newPageNumber, newPageSize, queryText);
  };

  const eventTypeLabels: Record<string, string> = {
    [niprEventSubtype.EntityInfo]: 'Entity Information',
    [niprEventSubtype.FeinLookup]: 'FEIN Lookup',
    [niprEventSubtype.SsnLookup]: 'SSN Lookup',
    [niprEventSubtype.LicenseLookup]: 'License Number Lookup',
    [niprEventSubtype.CreateScbReport]: 'Created SCB Report',
    [niprEventSubtype.RetrieveScbReport]: 'Retrieved SCB Report',
    [niprEventSubtype.CreateGatewayTransaction]: 'Created Gateway Transaction',
    [niprEventSubtype.PollGatewayTransaction]: 'Polled Gateway Transaction',
  };
  const pageName = PlausiblePageConstants.AGENCY_LICENSING_TRANSACTIONS;
  return (
    <Card
      style={{
        width: '100%',
        height: 'fit-content',
      }}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#0588ca',
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10,
            columnGap: 10,
          }}
        >
          {/* <Search
            placeholder="Search by Admin, NPN"
            size="middle"
            style={{
              width: '100%',
            }}
            value={searchText}
            // allowClear
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onSearch={(value) => {
              if (queryText !== value) {
                setQueryText(value);
                setPageNumber(1);
              }
            }}
            onPressEnter={(e) => {
              if (queryText !== (e.target as HTMLInputElement).value) {
                setQueryText((e.target as HTMLInputElement).value);
                setPageNumber(1);
              }
            }}
            disabled={isLoading}
          /> */}
          <FilterButton
            {...{
              filterInfoLoading,
              activeData: niprTransaction,
              requestBody,
              isLoading,
              setIsFilterVisible,
              pageName,
            }}
          />
        </div>
        <RenderFilterChip
          {...{
            selectedFilters,
            removeFilter,
            clearFilters,
            pageName,
          }}
        />
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}
        >
          <Table
            columns={NiprTransactionTable}
            dataSource={niprTransaction}
            pagination={false}
            onChange={(pagination, filters, sorter) => {
              const sort = applySort(sorter, fetchTransaction);
              setSortValue(sort);
            }}
            loading={isLoading}
          />
          {!isEmpty(niprTransaction) && !isLoading && (
            <Row justify="end">
              <Pagination
                showSizeChanger
                pageSize={pageSize}
                current={pageNumber}
                onChange={onPaginationChange}
                total={totalCount}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
              />
            </Row>
          )}
        </div>
        <SimpleFilter
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setApplyFilter={() => {}}
          setRequestBody={setRequestBody}
          requestBody={requestBody}
          filterLoader={filterInfoLoading}
          visibility={isFilterVisible}
          setVisibility={() => {
            setIsFilterVisible(false);
          }}
          fetchFilters={() => fetchFiltersInfoForNIPRTransaction()}
          clearFilters={() => {
            clearFilters();
          }}
          pageName={pageName}
        />
      </ConfigProvider>
    </Card>
  );
}

export default observer(NiprCost);
