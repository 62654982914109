import { Card, ConfigProvider, Switch, Tooltip, Typography } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PayeeEnum } from '../../../enums/payee.enum';
import { ProducerDetails } from '../../agents/onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import { TextDetail } from './dashboard.style';
import { agentSideStore } from '../../../stores/agentPortalStore';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
body {
  .ant-tooltip-inner {
    color: black;
    background-color: white;
  }
}
`;

type PaymentProps = {
  agent: ProducerDetails;
  onChange: (checked: boolean) => void;
  autoRenew: boolean;
};

const AgentPaymentConfig = ({ agent, onChange, autoRenew }: PaymentProps) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          marginTop: '20px',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            width: '45%',
          }}
        >
          <Typography.Paragraph style={{ fontSize: '14px', fontWeight: 500 }}>
            Initial payment account
            {!agent?.paymentConfig?.licenseInitialPayee && (
              <Tooltip title="Payments Not Configured">
                <GlobalStyle />
                <ExclamationCircleOutlined
                  style={{
                    paddingLeft: '10px',
                    color: 'red',
                  }}
                />
              </Tooltip>
            )}
          </Typography.Paragraph>
          <div
            style={{
              width: '98%',
              fontSize: '14px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <TextDetail
              fontSize={'14px'}
              fontWeight={500}
              textColor={
                agent?.paymentConfig?.licenseInitialPayee
                  ? ' var(--secondary-color)'
                  : '#92A4A6'
              }
            >
              {agent?.paymentConfig?.licenseInitialPayee === PayeeEnum.AGENCY
                ? agentSideStore.agencyName
                : agent?.paymentConfig?.licenseInitialPayee === PayeeEnum.AGENT
                ? 'Self'
                : 'Not Configured'}
            </TextDetail>
          </div>
        </div>
        <div
          style={{
            width: '45%',
          }}
        >
          <Typography.Paragraph style={{ fontSize: '14px', fontWeight: 500 }}>
            Renewal payment account
            {!agent?.paymentConfig?.licenseRenewalPayee && (
              <Tooltip title="Payments Not Configured">
                <GlobalStyle />
                <ExclamationCircleOutlined
                  style={{
                    paddingLeft: '10px',
                    color: 'red',
                  }}
                />
              </Tooltip>
            )}
          </Typography.Paragraph>
          <div
            style={{
              width: '98%',
              fontSize: '14px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <TextDetail
              fontSize={'14px'}
              fontWeight={500}
              textColor={
                agent?.paymentConfig?.licenseRenewalPayee
                  ? ' var(--secondary-color)'
                  : '#92A4A6'
              }
            >
              {agent?.paymentConfig?.licenseRenewalPayee === PayeeEnum.AGENCY
                ? agentSideStore.agencyName
                : agent?.paymentConfig?.licenseRenewalPayee === PayeeEnum.AGENT
                ? 'Self'
                : 'Not Configured'}
            </TextDetail>
          </div>
        </div>
      </div>

      <Card style={{ marginTop: '30px', fontSize: 14 }}>
        <Typography.Paragraph style={{ fontWeight: 500 }}>
          Setup Auto Apply
        </Typography.Paragraph>
        <hr className="secondary-line" />
        <div
          style={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}
        >
          <div
            style={{
              display: 'flex',
              marginRight: '20px',
              opacity:
                agent?.paymentConfig?.licenseRenewalPayee === PayeeEnum.AGENT
                  ? '100%'
                  : '50%',
            }}
          >
            <Typography.Paragraph
              style={{
                fontWeight: 500,
                marginRight: '30px',
              }}
            >
              Auto Apply
            </Typography.Paragraph>
            <ConfigProvider theme={{ token: { colorPrimary: '#0588ca' } }}>
              <Tooltip
                title={
                  agent?.paymentConfig?.licenseRenewalPayee === PayeeEnum.AGENCY
                    ? 'Renewal payment account is set to agency'
                    : null
                }
              >
                <Switch
                  onChange={onChange}
                  disabled={
                    agent?.paymentConfig?.licenseRenewalPayee ===
                    PayeeEnum.AGENCY
                  }
                  checked={
                    agent?.paymentConfig?.isAutoRenewalActive
                      ? agent?.paymentConfig?.isAutoRenewalActive
                      : false
                  }
                />
              </Tooltip>
            </ConfigProvider>
          </div>
          <div>
            {agent?.paymentConfig?.licenseRenewalPayee === PayeeEnum.AGENT && (
              <Typography.Paragraph
                style={{
                  fontWeight: 700,
                  color: 'var(--primary-color)',
                }}
              >
                Auto Apply is{' '}
                {agent?.paymentConfig?.isAutoRenewalActive ? 'On' : 'Off'}!
              </Typography.Paragraph>
            )}

            <Typography.Paragraph style={{ fontWeight: 500 }}>
              {agent?.paymentConfig?.licenseRenewalPayee === PayeeEnum.AGENT ? (
                autoRenew ? (
                  <TextDetail fontSize={'14px'} fontWeight={500}>
                    Your assigned licenses will be applied automatically and the
                    required amount will be deducted
                  </TextDetail>
                ) : (
                  <TextDetail fontSize={'14px'} fontWeight={500}>
                    Your assigned licenses will be not be applied automatically
                    and you will have to apply them manually
                  </TextDetail>
                )
              ) : (
                'Agency will have to configure their auto apply settings'
              )}
            </Typography.Paragraph>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AgentPaymentConfig;
