import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Popover,
  Row,
  Skeleton,
  Tooltip,
  Typography,
} from 'antd';
import {
  QuestionCircleOutlined,
  VideoCameraAddOutlined,
} from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { HeaderProps } from './header';
import { RoleType } from '../../enums/roles.enum';
import { RouteConstants } from '../../constants/routes.constants';
import SelectProfile from '../common/userProfile/selectProfile';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { observer } from 'mobx-react-lite';
import { stripeStore } from '../../stores/payment.store';
import supportLogo from '../../assets/icons/support.png';
import { tourStore } from '../../stores/tour.store';
import { withRoles } from '../../auth/useRoles';

const tourRoutes = [
  'Downlines',
  'By Agency',
  // 'Producer Onboarding',
  'By Producer',
  'Territories',
];

function AdminHeader({ headerText }: HeaderProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [assistanceOpen, setAssistanceOpen] = useState(false);

  const path = location.pathname;

  const [showSupportDropdown, setShowSupportDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowSupportDropdown(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const startTour = (headerText: string) => {
    switch (headerText) {
      case 'Downlines': {
        tourStore.setInviteDownlineDemoTourCurrentStep(0);
        tourStore.setInviteDownlineDemoTourPhase(1);
        tourStore.setInviteDownlineDemoTour(true);
        tourStore.setInviteDownlineDemoTourVisible(true);
        break;
      }
      case 'By Agency': {
        tourStore.setRtsByAgencyTour(true);
        tourStore.setRtsByAgencyCurrentStep(0);
        break;
      }
      // case 'Producer Onboarding': {
      //   tourStore.setProducerOnboardingTour(true);
      //   tourStore.setShowProducerOnboardTour(true);
      //   tourStore.setProducerOnboardingCurrentStep(0);
      //   tourStore.setProducerCompletedStep(0);
      //   break;
      // }
      case 'By Producer': {
        tourStore.setRtsByProducerTour(true);
        tourStore.setRtsByProducerVisible(true);
        tourStore.setRtsByProducerCurrentStep(0);
        break;
      }
      case 'Territories': {
        tourStore.setManageTerritoryTour(true);
        tourStore.setManageTerritoryCurrentStep(0);
        break;
      }
      default:
        break;
    }
  };

  const isGuidedTour = () =>
    tourRoutes.includes(headerText) ||
    (headerText === 'Wallet' && stripeStore.getStripeDetails()?.isOnboarded);

  const getTourButton = (headerText: string) => {
    if (!isGuidedTour()) return null;

    const isLoading =
      (headerText === 'By Producer' && tourStore.rtsByProducerLoading) ||
      (headerText === 'Wallet' && tourStore.walletLoading);

    const producerStepDisabled =
      tourStore.producerSection === 2 && headerText === 'Producer Onboarding';

    return (
      <Button
        loading={isLoading}
        disabled={isLoading || producerStepDisabled}
        onClick={() => {
          startTour(headerText);
        }}
      >
        {isLoading ? null : <VideoCameraAddOutlined />} Start Guided Tour
      </Button>
    );
  };

  return (
    <Card
      style={{
        height: '70px',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: '0px',
      }}
      bodyStyle={{
        padding: '0px 14px',
        display: 'flex',
        flexDirection: 'row',
      }}
      type="inner"
      className="header"
    >
      <Col style={{ flexGrow: 1 }}>
        <Row style={{ height: '55%', flexGrow: 1 }}>
          {appStateInfoStore.menuIcon}
          {headerText ? (
            <Typography
              style={{
                color: 'var(--primary-color)',
                fontWeight: '600',
                fontSize: '20px',
                lineHeight: '50px',
                marginLeft: '15px',
                margin: '0px 5px',
              }}
            >
              {headerText}
            </Typography>
          ) : (
            <Skeleton.Button active={true} size="small" />
          )}
          {appStateInfoStore.tooltip && (
            <Tooltip title={appStateInfoStore.tooltip}>
              <QuestionCircleOutlined style={{ fontSize: '12px !important' }} />
            </Tooltip>
          )}
        </Row>
        <Row>
          {/* Agency */}
          {path === RouteConstants.uplines.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Organization',
                  href: RouteConstants.agencyHierarchy.path,
                  onClick: (e) => {
                    navigate(RouteConstants.agencyHierarchy.path);
                    e.preventDefault();
                  },
                },
                {
                  title: 'Uplines',
                },
              ]}
            />
          )}
          {path === RouteConstants.licensesAgency.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Licenses',
                },
              ]}
            />
          )}
          {path === RouteConstants.invite_uplines.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Uplines',
                  href: RouteConstants.uplines.path,
                  onClick: (e) => {
                    navigate(RouteConstants.uplines.path);
                    e.preventDefault();
                  },
                },
                {
                  title: 'Invitations',
                },
              ]}
            />
          )}
          {path === RouteConstants.agencyHierarchy.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Organization',
                },
              ]}
            />
          )}
          {path === RouteConstants.downlines.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Organization',
                  href: RouteConstants.agencyHierarchy.path,
                  onClick: (e) => {
                    navigate(RouteConstants.agencyHierarchy.path);
                    e.preventDefault();
                  },
                },
                {
                  title: 'Downlines',
                },
              ]}
            />
          )}
          {headerText === RouteConstants.invite_downlines.headerText && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Downlines',
                  href: RouteConstants.downlines.path,
                  onClick: (e) => {
                    navigate(RouteConstants.downlines.path);
                    e.preventDefault();
                  },
                },
                {
                  title: 'Invitations',
                },
              ]}
            />
          )}
          {path === RouteConstants.agencyGeneralProfile.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Details',
                },
              ]}
            />
          )}
          {path === RouteConstants.bulkAddressUpdate.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'General',
                  href: RouteConstants.agencyGeneralProfile.path,
                  onClick: (e) => {
                    navigate(RouteConstants.agencyGeneralProfile.path);
                    e.preventDefault();
                  },
                },
                {
                  title: "Manage Producer's Contacts and Addresses",
                },
              ]}
            />
          )}

          {path === RouteConstants.admins.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Setup',
                },
                {
                  title: 'Users',
                },
              ]}
            />
          )}
          {path === RouteConstants.invite_admins.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Setup',
                },
                {
                  title: 'Users',
                  href: RouteConstants.admins.path,
                  onClick: (e) => {
                    navigate(RouteConstants.admins.path);
                    e.preventDefault();
                  },
                },
                {
                  title: 'Invitations',
                },
              ]}
            />
          )}
          {path === RouteConstants.agencyAdminProfile.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'My Account',
                },
                {
                  title: 'Account',
                },
              ]}
            />
          )}
          {path === RouteConstants.invites.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'My Account',
                },
                {
                  title: 'Team Invites',
                },
              ]}
            />
          )}
          {path === RouteConstants.agencyRTS.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Appointments',
                },
              ]}
            />
          )}
          {path === RouteConstants.agentRTS.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Producers',
                },
                {
                  title: 'Appointments',
                },
              ]}
            />
          )}
          {path.match(/\/rts\/agent-wise\/([0-9a-fA-F]{24})\/details/) && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Ready To Sell',
                },
                {
                  title: 'By Producer',
                  href: RouteConstants.agentRTS.path,
                  onClick: (e) => {
                    navigate(RouteConstants.agentRTS.path);
                    e.preventDefault();
                  },
                },
                {
                  title: headerText,
                },
              ]}
            />
          )}
          {/* Producers */}
          {path.match(/\/agent\/([0-9a-fA-F]{24})\/home/) && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'All Producers',
                  href: RouteConstants.allAgents.path,
                  onClick: (e) => {
                    navigate(RouteConstants.allAgents.path);
                    e.preventDefault();
                  },
                },
                {
                  title: headerText,
                },
                {
                  title: 'Home',
                },
              ]}
            />
          )}
          {path.match(/\/agent\/([0-9a-fA-F]{24})\/activity/) && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'All Producers',
                  href: RouteConstants.allAgents.path,
                  onClick: (e) => {
                    navigate(RouteConstants.allAgents.path);
                    e.preventDefault();
                  },
                },
                {
                  title: headerText,
                },
                {
                  title: 'Activity',
                },
              ]}
            />
          )}
          {path.match(/\/agent\/([0-9a-fA-F]{24})\/appointments/) && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'All Producers',
                  href: RouteConstants.allAgents.path,
                  onClick: (e) => {
                    navigate(RouteConstants.allAgents.path);
                    e.preventDefault();
                  },
                },
                {
                  title: headerText,
                },
                {
                  title: 'Appointments',
                },
              ]}
            />
          )}
          {path.match(/\/agent\/([0-9a-fA-F]{24})\/background-questions/) && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'All Producers',
                  href: RouteConstants.allAgents.path,
                  onClick: (e) => {
                    navigate(RouteConstants.allAgents.path);
                    e.preventDefault();
                  },
                },
                {
                  title: headerText,
                },
                {
                  title: 'Background Questions',
                },
              ]}
            />
          )}
          {path.match(/\/agent\/([0-9a-fA-F]{24})\/employment-history/) && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'All Producers',
                  href: RouteConstants.allAgents.path,
                  onClick: (e) => {
                    navigate(RouteConstants.allAgents.path);
                    e.preventDefault();
                  },
                },
                {
                  title: headerText,
                },
                {
                  title: 'Employment History',
                },
              ]}
            />
          )}
          {path.match(/\/agent\/([0-9a-fA-F]{24})\/support-documents/) && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'All Producers',
                  href: RouteConstants.allAgents.path,
                  onClick: (e) => {
                    navigate(RouteConstants.allAgents.path);
                    e.preventDefault();
                  },
                },
                {
                  title: headerText,
                },
                {
                  title: 'Support Documents',
                },
              ]}
            />
          )}
          {path.match(/\/agent\/([0-9a-fA-F]{24})\/needs-attention/) && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'All Producers',
                  href: RouteConstants.allAgents.path,
                  onClick: (e) => {
                    navigate(RouteConstants.allAgents.path);
                    e.preventDefault();
                  },
                },
                {
                  title: headerText,
                },
                {
                  title: 'Needs Attention',
                },
              ]}
            />
          )}
          {path === RouteConstants.manageAssignments.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Producers',
                },
                {
                  title: 'Assignments',
                },
              ]}
            />
          )}
          {path === RouteConstants.onboardAgents.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Producers',
                },
                {
                  title: 'Onboard',
                },
              ]}
            />
          )}
          {path === RouteConstants.offboardAgents.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Producers',
                },
                {
                  title: 'Offboard',
                },
              ]}
            />
          )}
          {path === RouteConstants.inviteDownline.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Downlines',
                  href: RouteConstants.downlines.path,
                  onClick: (e) => {
                    navigate(RouteConstants.downlines.path);
                    e.preventDefault();
                  },
                },
                {
                  title: 'Invite Downline',
                },
              ]}
            />
          )}

          {/* Agency Portal Licenses */}
          {path === RouteConstants.agencyLicenses.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Producers',
                },
                {
                  title: 'All Licenses',
                },
              ]}
            />
          )}
          {path === RouteConstants.agencyActionableLicenses.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Producers',
                },
                {
                  title: 'Needs Attention',
                },
              ]}
            />
          )}
          {path === RouteConstants.renewalLicenses.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Producers',
                },
                {
                  title: 'Upcoming Renewals',
                },
              ]}
            />
          )}
          {path === RouteConstants.agencyAgentApplications.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Producers',
                },
                {
                  title: 'Pending Applications',
                },
              ]}
            />
          )}
          {path === RouteConstants.agencyApplications.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Pending Applications',
                },
              ]}
            />
          )}

          {/* State Groups */}
          {path === RouteConstants.stateGroups.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Territories',
                },
              ]}
            />
          )}
          {path === RouteConstants.createStateGroup.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Territories',
                  href: RouteConstants.stateGroups.path,
                  onClick: (e) => {
                    navigate(RouteConstants.stateGroups.path);
                    e.preventDefault();
                  },
                },
                {
                  title: 'Create Territory',
                },
              ]}
            />
          )}
          {path.match(/\/territories\/edit\/([0-9a-fA-F]{24})/) && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Territories',
                  href: RouteConstants.stateGroups.path,
                  onClick: (e) => {
                    navigate(RouteConstants.stateGroups.path);
                    e.preventDefault();
                  },
                },
                {
                  title: appStateInfoStore.stateGroup,
                },
              ]}
            />
          )}
          {path === RouteConstants.blacklist.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Watchlist',
                },
              ]}
            />
          )}

          {path === RouteConstants.niprTransaction.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Licensing Transactions',
                },
              ]}
            />
          )}
          {path === RouteConstants.billing.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Setup',
                },
                {
                  title: 'Billing',
                },
              ]}
            />
          )}
          {/* {path === RouteConstants.MyAgencyBackgroundQuestions.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Background Questions',
                },
              ]}
            />
          )} */}
          {/* {path === RouteConstants.OwnershipDetails.path && (
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Agency',
                },
                {
                  title: 'Ownership Details',
                },
              ]}
            />
          )} */}
        </Row>
      </Col>
      <Col
        style={{
          flexGrow: 1,
        }}
      >
        <Row
          gutter={10}
          align="middle"
          justify={{ xl: 'space-around' }}
          style={{
            width: '100%',
            columnGap: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {getTourButton(headerText)}
          <SelectProfile />
          <Popover
            content={
              <div
                ref={dropdownRef}
                style={{
                  zIndex: 999,
                  background: '#fff',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
                  borderRadius: '5px',
                  fontSize: '12px',
                  outline: 'none',
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  e.currentTarget.style.boxShadow = 'none';
                }}
              >
                <p style={{ fontFamily: 'Poppins' }}>
                  Encountering issues, have suggestions, or need assistance?
                  <br />
                  Contact our support team, and we'll respond promptly.
                </p>
                <div style={{ textAlign: 'right' }}>
                  {' '}
                  <Button
                    onClick={() => {
                      window.location.href = `mailto:support@insuretrek.com`;
                    }}
                    size="small"
                    style={{ fontSize: '12px' }}
                    type="primary"
                  >
                    Raise an Issue
                  </Button>
                </div>
              </div>
            }
            trigger="click"
            open={assistanceOpen}
            onOpenChange={setAssistanceOpen}
          >
            <img
              src={supportLogo}
              alt="Support"
              style={{
                width: '50px',
                marginLeft: '0px',
                marginBottom: '3px',
                outline: 'none',
              }}
            />
          </Popover>
        </Row>
      </Col>
    </Card>
  );
}

export default withRoles(observer(AdminHeader), [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
