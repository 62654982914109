import { Button, Divider, Drawer, Spin, Typography } from 'antd';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import {
  Filter,
  FilterFieldsMetaData,
  FilterOptions,
  FiltersType,
} from '../../../types/common/filters.type';
import React, { useEffect, useState } from 'react';
import {
  trackCustomEvent,
  trackFilterUsage,
} from '../../../utils/trackingUtils';

import CheckBoxFilter from './filterOptionTypes/checkBoxFlter';
import DateFilter from './filterOptionTypes/dateFilter';
import EmptyState from '../emptyState/emptyState';
import { FilterType } from '../../../enums/filterType.enum';
import { PlausibleEventNameConstants } from '../../../constants/eventName.constants';
import { PlausiblePageConstants } from '../../../constants/page.constants';
import RadioButtonFilter from './filterOptionTypes/radioButtonFilter';
import TextWithIcon from '../textWithIcon/textWithIcon';
import isEqual from 'lodash/isEqual';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';

type SimpleFilterProps = {
  requestBody: Object;
  setRequestBody: React.Dispatch<React.SetStateAction<Object>>;
  appliedFilters?: Filter[];
  visibility: boolean;
  filterLoader: boolean;
  setVisibility: () => void;
  fetchFilters: () => Promise<FilterFieldsMetaData[]>;
  setApplyFilter?: () => void;
  selectedFilters: FiltersType;
  setSelectedFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  clearFilters: () => void;
  refreshFetchFilter?: boolean;
  setRefreshFetchFilter?: React.Dispatch<React.SetStateAction<boolean>>;
  controlledFilters?: FilterFieldsMetaData[];
  deleteParams?: () => void;
  pageName?: string;
};

const SimpleFilter: React.FC<SimpleFilterProps> = ({
  visibility,
  fetchFilters,
  requestBody,
  setVisibility,
  setRequestBody,
  filterLoader,
  selectedFilters: appliedFilters,
  setSelectedFilters: setAppliedFilters,
  clearFilters,
  refreshFetchFilter,
  setRefreshFetchFilter,
  controlledFilters,
  deleteParams,
  pageName = PlausiblePageConstants.UNKNOWN,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [filterInfo, setFilterInfo] = useState<FilterFieldsMetaData[]>();

  useEffect(() => {
    setSelectedFilters(appliedFilters);
  }, [appliedFilters]);

  const fetchData = async () => {
    if (!controlledFilters) {
      const newFilterInfo = await fetchFilters();
      newFilterInfo.sort(
        (a, b) => (a.displayOrder ?? Infinity) - (b.displayOrder ?? Infinity)
      );
      setFilterInfo(newFilterInfo);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (controlledFilters) {
      controlledFilters.sort(
        (a, b) => (a.displayOrder ?? Infinity) - (b.displayOrder ?? Infinity)
      );
      setFilterInfo(controlledFilters);
    }
  }, [controlledFilters]);

  useEffect(() => {
    refreshFetchFilter && fetchData();
    setRefreshFetchFilter && setRefreshFetchFilter(false);
  }, [refreshFetchFilter]);

  const setRequest = () => {
    deleteParams && deleteParams();
    const baseRequestBody = { ...requestBody };
    const filterGroups = setFilterGroups(selectedFilters);
    const updatedRequestBody = {
      ...baseRequestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
    setVisibility();
    setAppliedFilters(selectedFilters);
  };

  const onClose = () => {
    setVisibility();
    setSelectedFilters(appliedFilters);
    trackCustomEvent(PlausibleEventNameConstants.CLOSE_FILTER_OPTION, pageName);
  };

  return (
    <Drawer
      open={visibility}
      onClose={onClose}
      width={400}
      closable
      destroyOnClose={true}
      closeIcon={<CloseOutlined />}
      placement="right"
      title={
        <div>
          <TextWithIcon
            iconAlignment="left"
            text="Filters"
            icon={<FilterOutlined />}
            textStyle={{
              color: 'var(--secondary-color)',
              fontSize: '14px',
              fontWeight: 500,
            }}
            iconColor="var(--primary-color)"
            iconSize="large"
          />
        </div>
      }
    >
      {filterInfo?.length ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              height: 'calc(100vh - 107px)',
            }}
          >
            <div
              className="custom-scrollbar"
              style={{
                overflow: 'auto',
              }}
            >
              {filterInfo?.map((filter, index) => (
                <div key={filter.key}>
                  {filter.filterOptions.length > 0 && (
                    <>
                      {filter.filterType === FilterType.CHECKBOX && (
                        <CheckBoxFilter
                          keyData={filter.key}
                          options={filter.filterOptions as FilterOptions[]}
                          label={filter.filterLabel}
                          filters={selectedFilters}
                          setFilters={setSelectedFilters}
                          search={filter.showSearch}
                        />
                      )}
                      {filter.filterType === FilterType.RADIO && (
                        <RadioButtonFilter
                          keyData={filter.key}
                          filters={selectedFilters}
                          options={filter.filterOptions as FilterOptions[]}
                          label={filter.filterLabel}
                          setFilters={setSelectedFilters}
                        />
                      )}
                      {filterInfo.length !== index + 1 && (
                        <Divider orientation="center" />
                      )}
                    </>
                  )}
                  {filter.filterType === FilterType.DATE && (
                    <>
                      <DateFilter
                        keyData={filter.key}
                        options={filter.filterOptions as FilterOptions[]}
                        label={filter.filterLabel}
                        filters={appliedFilters}
                        selectedFilters={selectedFilters}
                        setFilters={setSelectedFilters}
                      />
                      {filterInfo.length !== index + 1 && (
                        <Divider orientation="center" />
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '30px',
              }}
            >
              <Button
                size="large"
                id="filters-button"
                style={{
                  width: '48%',
                  background: 'var(--hint-primary-color)',
                }}
                onClick={() => {
                  setRequest();
                  trackFilterUsage(pageName, Object.keys(selectedFilters.data));
                }}
                disabled={
                  !!Object.values(selectedFilters).length &&
                  isEqual(selectedFilters, appliedFilters)
                }
              >
                <Typography style={{ color: 'white' }}>
                  Apply Filters
                </Typography>
              </Button>
              <Button
                id="filters-button"
                disabled={!Object.keys(selectedFilters?.data || {}).length}
                size="large"
                style={{
                  width: '48%',
                  background: 'white',
                  border: '1px solid #333',
                }}
                onClick={() => {
                  if (Object.keys(appliedFilters.data).length) clearFilters();
                  else setSelectedFilters(appliedFilters);
                  setVisibility();
                  trackCustomEvent(
                    PlausibleEventNameConstants.CLEAR_FILTER_OPTION,
                    pageName
                  );
                }}
              >
                <Typography>Clear All Filters</Typography>
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="flex-center" style={{ marginTop: '24px' }}>
          {filterLoader ? (
            <p>
              {' '}
              <Spin size="small" />{' '}
              <span style={{ marginLeft: 6 }}>Fetching Filters...</span>
            </p>
          ) : (
            <EmptyState
              image="empty data"
              heading="No Filter Records Available"
              content=""
            />
          )}
        </div>
      )}
    </Drawer>
  );
};

export default SimpleFilter;
