import { Button, Col, Modal, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';

import { ActionableLicenseResponseError } from '../../types/response/actionable-license.type';
import { ApplicationStatusEnum } from '../../enums/applicationStatus.enum';
import { ApplicationTypeEnumInBackend } from '../../enums/applicationType.enum';
import { ErrorResponse } from '../../types/response/errorResponse.type';
import { ExceptionName } from '../../enums/exceptionName.enum';
import { GatewayTransactionApplyError } from '../../types/nipr/gatewayTransaction.types';
import { LicenseApplicationResponse } from '../../types/response/license-application.type';
import { LicensesService } from '../../services/licenses.service';
import { PayeeEnum } from '../../enums/payee.enum';
import { PaymentTypeEnum } from '../../enums/paymentType.enum';
import RequirementErrorDiv from './requirementErrorDiv';
import { RouteConstants } from '../../constants/routes.constants';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { getNameString } from '../../utils/name.utils';
import { useAuth } from '../../auth/authProvider';

const { Title } = Typography;

interface PreApplicationErrorModalProps {
  modalVisibility: boolean;
  setModalVisibility: (prop: boolean) => void;
  record: LicenseApplicationResponse;
  currentTab: string;
  showError: (prop: string) => void;
  setErrors?: React.Dispatch<
    React.SetStateAction<ActionableLicenseResponseError[]>
  >;
  setApplyLicenseErrorModalVisibility?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setDisableApplyButton: React.Dispatch<React.SetStateAction<boolean>>;
  deleteAppliedLicense: (id: string) => void;
  setPayerCallBack: () => void;
}

function PreApplicationErrorModal(props: PreApplicationErrorModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [payer, setPayer] = useState();
  const { getAccessTokenSilently } = useAuth();

  useEffect(() => {
    if (
      props.record.applicationType === ApplicationTypeEnumInBackend.creation
    ) {
      setPayer(props.record.agent?.paymentConfig?.initialPaymentPayee);
    } else {
      setPayer(props.record.agent?.paymentConfig?.renewalPaymentPayee);
    }
  }, [props.currentTab, props.record]);

  const applyLicense = async () => {
    if (
      payer !== 'Agency' ||
      !props.record.agent.isOnboarded ||
      !props.record.agent.employmentHistoryStatus
    )
      return;
    await applyOneLicense(props.record);
  };

  const applyOneLicense = async (license: LicenseApplicationResponse) => {
    if (payer !== 'Agency') return;

    try {
      setIsLoading(true);
      if (license?.status === ApplicationStatusEnum.Applied) {
        return;
      } else {
        const token = await getAccessTokenSilently();
        const resp: any = await LicensesService.applyLicenses(
          license.id,
          token
        ).catch((e: ErrorResponse) => {
          if (e.error.exceptionName === ExceptionName.BAD_REQUEST_EXCEPTION) {
            props.showError(e.error.message);
          } else if (
            e.error.exceptionName ===
            ExceptionName.NIPR_GATEWAY_TRANSACTION_EXCEPTION
          ) {
            const error = e.error.data as GatewayTransactionApplyError;
            const lcRespMessages = error.lcRespMessages
              .filter((itr) => !!itr)
              .map((itr) => itr.comment);

            const loaRespMessages = error.loaRespMessages
              .filter((itr) => !!itr)
              .flatMap((itr) =>
                itr.approved !== 'Y'
                  ? itr.respMessages.map((msg) => msg.comment)
                  : []
              );
            const mainErrorMessages = error.mainErrorMessages
              .filter((itr) => !!itr)
              .flatMap((itr) => itr.description);
            const intermediateMessages = error.intermediateMessages
              .filter((itr) => !!itr)
              .flatMap((itr) => {
                return { actionable: itr.actionRequired, message: itr.comment };
              });

            const lcErrorDetails = { errorMessages: lcRespMessages };
            const loaErrorDetails = { errorMessages: loaRespMessages };
            const mainErrorDetails = { errorMessages: mainErrorMessages };
            const intermediateErrorDetails = {
              errorMessages: intermediateMessages,
            };

            props.setErrors &&
              props.setErrors([
                {
                  LcErrorDetails: lcErrorDetails,
                  LoaErrorDetails: loaErrorDetails,
                  mainErrorDetails: mainErrorDetails,
                  intermediateErrorDetails: intermediateErrorDetails,
                },
              ]);
            props.setApplyLicenseErrorModalVisibility &&
              props.setApplyLicenseErrorModalVisibility(true);
          }
        });

        if (resp.status === 'SUCCESS') {
          message.success('License Applied SuccessFully');
          props.setDisableApplyButton(false);
          props.deleteAppliedLicense(license.id);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      props.setDisableApplyButton(false);
      message.error('Failed To Apply License');
      console.error('error message :: ', error);
    } finally {
      props.setModalVisibility(false);
    }
  };
  return (
    <Modal
      open={props.modalVisibility}
      onCancel={() => {
        props.setModalVisibility(false);
      }}
      maskClosable={false}
      closable={!isLoading}
      destroyOnClose
      width="600px"
      style={{ boxShadow: 'none' }}
      footer={
        <Col
          style={{
            display: 'flex',
            margin: '25px',
            flexDirection: 'row-reverse',
            columnGap: '10px',
          }}
        >
          {payer === PayeeEnum.AGENCY &&
            props.record.agent?.backgroundQuestionsStatus &&
            props.record.agent?.employmentHistoryStatus &&
            props.record.agent?.paymentConfigStatus && (
              <Button
                style={{
                  width: '100%',
                }}
                danger
                onClick={(e) => {
                  e.preventDefault();
                  applyLicense();
                }}
                loading={isLoading}
              >
                Proceed with application anyway
              </Button>
            )}
          <Button
            style={{
              width: '100%',
              marginLeft: '0px',
            }}
            onClick={() => {
              props.setModalVisibility(false);
            }}
            disabled={isLoading}
          >
            Go Back
          </Button>
        </Col>
      }
    >
      <Col
        style={{
          margin: '25px',
          rowGap: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Title level={3} style={{ marginTop: '0px' }}>
          Applying for{' '}
          <span style={{ color: 'var(--primary-color)' }}>
            {getNameString(props.record.agent?.name)}
          </span>{' '}
          in the state of{' '}
          <span style={{ color: 'var(--primary-color)' }}>
            {props.record.license.stateName}
          </span>
        </Title>
        <Title style={{ marginTop: '0px' }} level={5}>
          Pending Requirements
        </Title>
        {payer !== PayeeEnum.AGENCY && (
          <RequirementErrorDiv
            isLoading={isLoading}
            onClick={() => {
              if (isLoading) return;
              props.setPayerCallBack();
              props.setModalVisibility(false);
            }}
            text={
              <>
                {!payer &&
                  'Please configure who will pay for this license before proceeding with the application.'}
                {payer === PayeeEnum.AGENT &&
                  'Please configure payer to be agency for this license before proceeding with the application. Current payer is set to agent.'}
              </>
            }
          />
        )}
        {payer === PayeeEnum.AGENCY &&
          !(
            props?.record?.agent?.paymentConfig?.paymentType ===
              PaymentTypeEnum.CAP ||
            props?.record?.agent?.paymentConfig?.paymentType ===
              PaymentTypeEnum.FULL
          ) && (
            <RequirementErrorDiv
              isLoading={isLoading}
              onClick={() => {
                if (isLoading) return;
                props.setPayerCallBack();
                props.setModalVisibility(false);
              }}
              text={<>Please configure the payment type for this agent.</>}
            />
          )}
        {!props.record.agent?.backgroundQuestionsStatus && (
          <RequirementErrorDiv
            isLoading={isLoading}
            text="Producer's background questions need to be answered to be able to apply for a license through NIPR."
            onClick={() => {
              if (isLoading) return;
              if (
                !props.record.agent?.backgroundQuestionsStatus &&
                props.record?.agent?.id
              ) {
                appStateInfoStore.setHeader(
                  getNameString(props.record.agent.name)
                );
                window.open(
                  `${
                    process.env.REACT_APP_REDIRECT_URI
                  }${RouteConstants.agencyBackgroundQuestions.path.replace(
                    ':agentId',
                    props.record?.agent?.id
                  )}`,
                  '_blank',
                  'rel=noopener noreferrer'
                );
              }
            }}
          />
        )}
        {!props.record.agent.employmentHistoryStatus && (
          <RequirementErrorDiv
            isLoading={isLoading}
            text="Producer needs to have employment history of 5 consecutive years to be to able to apply for a license through NIPR."
            onClick={() => {
              if (isLoading) return;
              if (
                !props.record.agent.employmentHistoryStatus &&
                props.record?.agent?.id
              ) {
                appStateInfoStore.setHeader(
                  getNameString(props.record.agent.name)
                );
                window.open(
                  `${
                    process.env.REACT_APP_REDIRECT_URI
                  }${RouteConstants.agencyEmploymentHistory.path.replace(
                    ':agentId',
                    props.record?.agent?.id
                  )}`,
                  '_blank',
                  'rel=noopener noreferrer'
                );
              }
            }}
          />
        )}
      </Col>
    </Modal>
  );
}

export default PreApplicationErrorModal;
