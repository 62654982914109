import { Row, Typography } from 'antd';

import { LicenseStatus } from '../../enums/licenseStatus.enum';
import { convertStringToTitleCase } from '../../utils/common.utils';

function LicenseStatusCell({
  licenseStatus,
  styles,
}: {
  licenseStatus: string;
  styles?: Object;
}) {
  return (
    <Row align="middle" style={{ textAlign: 'center', ...styles }}>
      <Typography.Paragraph
        style={{
          fontSize: '14px',
          fontWeight: 500,
          color:
            licenseStatus.toLowerCase() === LicenseStatus.Active
              ? 'var(--success-color)'
              : 'var(--important-color)',
        }}
      >
        {convertStringToTitleCase(licenseStatus)}
      </Typography.Paragraph>
    </Row>
  );
}

export default LicenseStatusCell;
