import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  Typography,
} from 'antd';
import { EMAIL_REGEX, SSN_REGEX } from '../../../constants/regex.constants';
import {
  formatPhoneAndFax,
  isPhoneNumberValid,
} from '../../../utils/form-utils/format-phone-fax.utils';
import { useEffect, useState } from 'react';

import { AddPaymentOptionModal } from './addPaymentOptionModal';
import { Address } from '../../../types/data/address.type';
import AddressInput from '../../../components/common/addressInput';
import { ExceptionName } from '../../../enums/exceptionName.enum';
import { GenderType } from '../../../enums/genderType.enum';
import { Name } from '../../../types/data/name.type';
import { SignupService } from '../../../services/signup.service';
import { convertMomentToDate } from '../../../utils/date.utils';
import dayjs from 'dayjs';
import { getDefaultAddress } from '../../../utils/address.utils';
import moment from 'moment';
import { notification } from 'antd';
import { useAuth } from '../../authProvider';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface Details {
  npn: string;
  name: Name | string;
  email: string;
  businessEmail: string;
  businessPhone: string;
  fax: string;
  ssn?: string;
  dateOfBirth?: Date;
  gender?: String;
  businessAddress: Address;
  mailingAddress: Address;
  residenceAddress: Address;
  residentState: string;
  stateAddresses?: { stateCode: string; addresses: Address[] }[];
  fein?: string;
}

export interface UserData {
  agency?: Details;
  producer?: Details;
}

export const VerifyDetails = () => {
  const [form] = Form.useForm();
  const [producerDetails, setProducerDetails] = useState<Details>();
  const [agencyDetails, setAgencyDetails] = useState<Details>();
  const [agencyDetailsEnabled, setAgencyDetailsEnabled] =
    useState<boolean>(false);
  const [producerDetailsEnabled, setProducerDetailsEnabled] =
    useState<boolean>(false);
  const [bothAgencyandProducer, setBothAgencyAndProducer] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [stripeLinkFromResponse, setStripeLinkFromResponse] =
    useState<string>('');

  const handleConfirm = () => {
    if (stripeLinkFromResponse) {
      window.location.href = stripeLinkFromResponse;
    }
  };

  const { Option } = Select;

  const onFinish = async (values: any) => {
    const updatedDetails: Details = {
      npn: values.npn,
      fein: agencyDetails?.fein,
      name: agencyDetailsEnabled
        ? values.name
        : {
            firstName: values.firstName,
            lastName: values.lastName,
          },
      email: values.contactEmail,
      businessEmail: values.businessEmail,
      businessPhone: values.phone,
      fax: values.fax,
      ...(producerDetailsEnabled
        ? {
            ssn: values.ssn,
            dateOfBirth: values.dateOfBirth
              ? convertMomentToDate(values.dateOfBirth)
              : new Date(),
            gender: values.gender,
          }
        : {}),

      residentState: agencyDetails
        ? agencyDetails.residentState
        : producerDetails?.residentState || '',
      businessAddress: {
        addressLine1: values.baddressLine1,
        addressLine2: values.baddressLine2,
        addressLine3: values.baddressLine3,
        city: values.bcity,
        state: values.bstate,
        zip: values.bzipcode,
        country: values.bcountry,
      },
      mailingAddress: {
        addressLine1: values.maddressLine1,
        addressLine2: values.maddressLine2,
        addressLine3: values.maddressLine3,
        city: values.mcity,
        state: values.mstate,
        zip: values.mzipcode,
        country: values.mcountry,
      },
      residenceAddress: {
        addressLine1: values.raddressLine1,
        addressLine2: values.raddressLine2,
        addressLine3: values.raddressLine3,
        city: values.rcity,
        state: values.rstate,
        zip: values.rzipcode,
        country: values.rcountry,
      },
    };
    try {
      if (bothAgencyandProducer) {
        if (await verifyDetails({ agencyDetails: updatedDetails })) {
          setBothAgencyAndProducer(false);
          setAgencyDetailsEnabled(false);
          setProducerDetailsEnabled(true);
          setAgencyDetails(updatedDetails);
        }
      } else if (agencyDetailsEnabled) {
        if (await verifyDetails({ agencyDetails: updatedDetails }, true)) {
          setAgencyDetails(updatedDetails);
        }
      } else if (producerDetailsEnabled) {
        if (await verifyDetails({ producerDetails: updatedDetails }, true)) {
          setProducerDetails(updatedDetails);
        }
      }
    } catch (error) {
      console.error('Verification failed:', error);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [agencyDetails, producerDetails]);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (!token) return;
      const userInfo: UserData = await SignupService.getDetails(token);
      if (userInfo.agency && userInfo.producer) {
        setBothAgencyAndProducer(true);
        setAgencyDetailsEnabled(true);
        setAgencyDetails(userInfo.agency);
        setProducerDetails(userInfo.producer);
      } else if (userInfo.agency) {
        setAgencyDetailsEnabled(true);
        setAgencyDetails(userInfo.agency);
      } else if (userInfo.producer) {
        setProducerDetailsEnabled(true);
        setProducerDetails(userInfo.producer);
      }
    } catch (error) {
      console.error('Error occurred while verifying details:', error);
    } finally {
      setLoading(false);
    }
  };

  const verifyDetails = async (
    requestBody: { agencyDetails?: Details; producerDetails?: Details },
    OnboardingStatus?: boolean
  ) => {
    setLoading(true);
    // if (requestBody.agencyDetails) {
    // requestBody.agencyDetails.stateAddresses = [
    //   {
    //     stateCode: requestBody.agencyDetails.residentState,
    //     addresses: [],
    //   },
    // ];
    // if (
    //   Object.values(requestBody.agencyDetails.businessAddress).filter(
    //     (itr) => !!itr
    //   ).length > 0
    // )
    //   requestBody.agencyDetails.stateAddresses[0].addresses.push({
    //     ...requestBody.agencyDetails.businessAddress,
    //     addressTypeCode: '2',
    //     addressType: 'Business',
    //   });
    // if (
    //   Object.values(requestBody.agencyDetails.mailingAddress).filter(
    //     (itr) => !!itr
    //   ).length > 0
    // )
    //   requestBody.agencyDetails.stateAddresses[0].addresses.push({
    //     ...requestBody.agencyDetails.mailingAddress,
    //     addressTypeCode: '3',
    //     addressType: 'Mailing',
    //   });
    // if (
    //   Object.values(requestBody.agencyDetails.residenceAddress).filter(
    //     (itr) => !!itr
    //   ).length > 0
    // )
    //   requestBody.agencyDetails.stateAddresses[0].addresses.push({
    //     ...requestBody.agencyDetails.residenceAddress,
    //     addressTypeCode: '1',
    //     addressType: 'Residence',
    //   });
    // }
    try {
      const token = await getAccessTokenSilently();
      const requestPayload: {
        entityType: 'agency' | 'producer';
        agency?: any;
        producer?: any;
      } = {
        entityType: requestBody.agencyDetails ? 'agency' : 'producer',
      };
      if (requestBody.agencyDetails)
        requestPayload.agency = requestBody.agencyDetails;
      else if (requestBody.producerDetails)
        requestPayload.producer = requestBody.producerDetails;
      if (!token) return;
      const response = await SignupService.VerifyDetails(
        token,
        requestPayload,
        OnboardingStatus
      );
      if (response && !bothAgencyandProducer) {
        if (response?.data?.response)
          setStripeLinkFromResponse(response?.data?.response);
        else setStripeLinkFromResponse(response.data);
        setIsModalVisible(true);
      }
      return true;
    } catch (error: any) {
      console.error('Error :: verifyDetails ::', error);
      if (
        error?.response?.data?.error?.exceptionName ===
          ExceptionName.DUPLICATE_VALUE_EXCEPTION &&
        error?.response?.data?.error?.key === 'email'
      ) {
        notification.error({
          message: 'Duplicate Account Detected',
          description:
            'An account with this email address already exists. Please use a different email address.',
        });
        form.scrollToField('contactEmail', {
          behavior: 'smooth',
          block: 'center',
        });
      }
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleFinishFailed = ({ errorFields }: any) => {
    notification.error({
      message: 'Incomplete Form Submission',
      description: 'Please fill in the required fields.',
    });

    // Optional: Highlight the first error field
    if (errorFields.length > 0) {
      form.scrollToField(errorFields[0].name, {
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Spin spinning={loading}>
      <Typography.Paragraph
        style={{
          color: 'var(--secondary-color)',
          fontSize: '20px',
          fontWeight: 600,
        }}
      >
        {!producerDetailsEnabled
          ? 'Verify your Agency Details'
          : 'Verify your Producer Details'}
      </Typography.Paragraph>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={handleFinishFailed}
        disabled={loading}
        className="onboarding-screen"
      >
        {/* First Name */}
        {producerDetailsEnabled && (
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: 'Please enter your first name' },
            ]}
            initialValue={
              typeof producerDetails?.name === 'object'
                ? producerDetails?.name?.firstName
                : ''
            }
            style={{ marginBottom: '10px' }}
          >
            <Input disabled placeholder="First Name" />
          </Form.Item>
        )}
        {producerDetailsEnabled && (
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: 'Please enter your last name' }]}
            initialValue={
              typeof producerDetails?.name === 'object'
                ? producerDetails?.name?.lastName
                : ''
            }
            style={{ marginBottom: '10px' }}
          >
            <Input disabled placeholder="Last Name" />
          </Form.Item>
        )}
        {/* Agency Name */}
        {!producerDetailsEnabled && (
          <Form.Item
            label="Agency Name"
            name="name"
            rules={[
              { required: true, message: 'Please enter your agency name!' },
            ]}
            initialValue={
              typeof agencyDetails?.name === 'string' ? agencyDetails.name : ''
            }
            style={{ marginBottom: '10px' }}
          >
            <Input disabled placeholder="Agency Name" />
          </Form.Item>
        )}
        {/* Email Address */}
        <Form.Item
          label="Email Address"
          name="businessEmail"
          rules={[
            { required: true, message: 'Please enter your email address' },
          ]}
          initialValue={
            producerDetailsEnabled
              ? producerDetails?.businessEmail
              : agencyDetails?.businessEmail
          }
          style={{ marginBottom: '10px' }}
        >
          <Input disabled placeholder="Email Address" />
        </Form.Item>

        {producerDetailsEnabled && (
          <Form.Item
            label="Contact Email"
            name="contactEmail"
            rules={
              // when input field is disable we don't want to make this validation
              producerDetails?.email === '' ||
              producerDetails?.email === undefined ||
              producerDetails?.email === null
                ? [
                    {
                      required: true,
                      message: 'Please enter your email address',
                    },
                    {
                      pattern: EMAIL_REGEX,
                      message: 'The input is not a valid email',
                    },
                    {
                      validator: (_, value) =>
                        /[A-Z]/.test(value)
                          ? Promise.reject(
                              new Error(
                                'Email should not contain capital letters'
                              )
                            )
                          : Promise.resolve(),
                    },
                  ]
                : []
            }
            initialValue={producerDetails?.email}
            style={{ marginBottom: '10px' }}
          >
            <Input
              disabled={
                !(
                  producerDetails?.email === '' ||
                  producerDetails?.email === undefined ||
                  producerDetails?.email === null
                )
              }
              placeholder="Contact Email"
            />
          </Form.Item>
        )}
        <Form.Item
          label="NPN"
          name="npn"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={
            producerDetailsEnabled ? producerDetails?.npn : agencyDetails?.npn
          }
          style={{ marginBottom: '10px' }}
        >
          <Input disabled placeholder="NPN" />
        </Form.Item>

        {producerDetailsEnabled && (
          <>
            <Form.Item
              label="SSN"
              name="ssn"
              rules={
                // making this conditional because we get encrypted ssn when a producer is existing in our system
                !producerDetails?.ssn
                  ? [
                      {
                        required: true,
                        pattern: SSN_REGEX,
                        message: 'Please enter a valid ssn',
                      },
                      // {
                      //   validator: (_, value) =>
                      //     value.length === 9
                      //       ? Promise.resolve()
                      //       : Promise.reject(),
                      //   message: 'SSN needs to be 9 digits',
                      // },
                    ]
                  : []
              }
              initialValue={producerDetails?.ssn}
              style={{ marginBottom: '10px' }}
            >
              <Input.Password
                placeholder="SSN"
                maxLength={9}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                disabled={producerDetails?.ssn ? true : false}
                onPaste={(event) => {
                  const pastedText = event.clipboardData.getData('text/plain');
                  if (!/^[0-9]+$/.test(pastedText)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="Date of Birth"
              name="dateOfBirth"
              rules={[
                {
                  required: true,
                  message: 'Please select your date of birth',
                },
              ]}
              style={{ marginBottom: '10px' }}
              initialValue={
                dayjs(producerDetailsEnabled && producerDetails?.dateOfBirth) ||
                undefined
              }
            >
              <DatePicker
                disabled={
                  producerDetailsEnabled && producerDetails?.dateOfBirth
                    ? true
                    : false
                }
                format="MM/DD/YYYY"
                disabledDate={(current) =>
                  !current.isBefore(moment().subtract(18, 'years').toDate())
                }
              />
            </Form.Item>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[
                {
                  required: true,
                  message: 'Please select your gender',
                },
              ]}
              initialValue={
                (producerDetailsEnabled && producerDetails?.gender) || ''
              }
            >
              <Select
                placeholder="Select Gender"
                style={{ marginBottom: '10px' }}
                disabled={producerDetails?.gender !== 'Unknown' ? true : false}
              >
                <Option value={GenderType.Male}>Male</Option>
                <Option value={GenderType.Female}>Female</Option>
              </Select>
            </Form.Item>{' '}
          </>
        )}
        {/* Phone and Fax */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '10px',
            marginBottom: '10px',
          }}
        >
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              { required: true },
              () => ({
                validator(_, value) {
                  const numericValue = value.replace(/\D/g, '');
                  const formattedValue = formatPhoneAndFax(numericValue);
                  if (!value || isPhoneNumberValid(formattedValue)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Please enter a valid phone number');
                },
              }),
            ]}
            initialValue={producerDetails?.businessPhone}
            style={{ flex: 1 }}
          >
            <Input
              placeholder="Enter Phone number"
              disabled={producerDetails?.businessPhone ? true : false}
              onChange={(e) => {
                const formattedValue = formatPhoneAndFax(e.target.value);
                if (!e.target.value || isPhoneNumberValid(formattedValue)) {
                  form.setFieldsValue({ phone: formattedValue });
                }
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={(event) => {
                const pastedText = event.clipboardData.getData('text/plain');
                if (!/^[0-9]+$/.test(pastedText)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Fax"
            name="fax"
            initialValue={
              producerDetailsEnabled ? producerDetails?.fax : agencyDetails?.fax
            }
            rules={[
              () => ({
                validator(_, value) {
                  if (!value) return Promise.resolve();

                  const numericValue = value.replace(/\D/g, '');
                  const formattedValue = formatPhoneAndFax(numericValue);
                  if (isPhoneNumberValid(formattedValue)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Please enter a valid fax');
                },
              }),
            ]}
            style={{ flex: 1 }}
          >
            <Input
              placeholder="Enter FAX Number"
              onChange={(e) => {
                const formattedValue = formatPhoneAndFax(e.target.value);
                if (!e.target.value || isPhoneNumberValid(formattedValue)) {
                  form.setFieldsValue({ fax: formattedValue });
                }
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={(event) => {
                const pastedText = event.clipboardData.getData('text/plain');
                if (!/^[0-9]+$/.test(pastedText)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        </div>
        {/* Business, Mailing, and Resident Addresses */}
        <AddressInput
          viewOnly={true}
          addressDetails={
            agencyDetailsEnabled
              ? agencyDetails?.businessAddress || getDefaultAddress()
              : producerDetails?.businessAddress || getDefaultAddress()
          }
          addressType="business"
          style={{ width: '100%', display: 'block', marginBottom: '10px' }}
          formInputStyle={{ marginBottom: '10px' }}
        />
        <AddressInput
          viewOnly={true}
          addressDetails={
            agencyDetailsEnabled
              ? agencyDetails?.mailingAddress || getDefaultAddress()
              : producerDetails?.mailingAddress || getDefaultAddress()
          }
          addressType="mailing"
          style={{ width: '100%', display: 'block', marginBottom: '10px' }}
          formInputStyle={{ marginBottom: '10px' }}
        />
        <AddressInput
          viewOnly={true}
          addressDetails={
            agencyDetailsEnabled
              ? agencyDetails?.residenceAddress || getDefaultAddress()
              : producerDetails?.residenceAddress || getDefaultAddress()
          }
          addressType="residence"
          style={{ width: '100%', display: 'block' }}
          formInputStyle={{ marginBottom: '10px' }}
        />

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              background: 'var(--primary-color)',
              color: 'white',
              minWidth: '150px',
              height: '40px',
              display: 'block',
              marginTop: '10px',
            }}
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
      <AddPaymentOptionModal
        visible={isModalVisible}
        onConfirm={handleConfirm}
      />
    </Spin>
  );
};
