import { Button } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { IdConstants } from '../constants/id.constants';
import { PlausibleEventNameConstants } from '../constants/eventName.constants';
import { PlausiblePageConstants } from '../constants/page.constants';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { isDisableFilter } from '../utils/common.utils';
import { trackCustomEvent } from '../utils/trackingUtils';

interface FilterButtonType {
  filterInfoLoading: boolean;
  requestBody: Object;
  activeData: any;
  isLoading: boolean;
  setIsFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  styles?: Object;
  buttonSize?: SizeType;
  disableButton?: boolean;
  pageName?: string;
}

const FilterButton = ({
  filterInfoLoading,
  requestBody,
  activeData,
  isLoading,
  setIsFilterVisible,
  styles,
  buttonSize,
  disableButton,
  pageName = PlausiblePageConstants.UNKNOWN,
}: FilterButtonType) => {
  return (
    <Button
      id={IdConstants.FILTERS_BUTTON}
      loading={filterInfoLoading}
      icon={<FilterOutlined />}
      {...(buttonSize ? { size: buttonSize } : {})}
      style={{
        marginLeft: 'auto',
        background: 'var(--hint-primary-color)',
        color: 'white',
        ...(styles ? styles : {}),
      }}
      disabled={
        isDisableFilter(activeData, requestBody) ||
        isLoading ||
        filterInfoLoading ||
        (disableButton && disableButton)
      }
      onClick={() => {
        setIsFilterVisible(true);
        trackCustomEvent(PlausibleEventNameConstants.FILTER_OPTION, pageName);
      }}
    >
      Filters
    </Button>
  );
};

export default FilterButton;
