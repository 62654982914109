import './manageAssignments.css';

import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Input,
  Pagination,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
  notification,
} from 'antd';
import {
  EditOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import React, { useEffect, useState } from 'react';
import {
  applySort,
  getStateNameFromCode,
  getTextColor,
  stringToColor,
} from '../../../utils/common.utils';
import axios, { CancelTokenSource } from 'axios';
import { groupBy, isEmpty, sortBy } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AgentService } from '../../../services/agent.service';
import AssignModal from '../../../components/assignModal/assignModal';
import { AssignedState } from '../../../types/data/stateGroup.types';
import { AssignmentAgent } from '../../../types/data/allAgents.type';
import { ColumnsType } from 'antd/es/table';
import FilterButton from '../../../components/filterButton';
import { IdConstants } from '../../../constants/id.constants';
import { ManageAssignmentsTab } from '../../../enums/manageAssignments.enum';
import { PlausiblePageConstants } from '../../../constants/page.constants';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import StatesCircled from '../../../components/common/statesCircled/StatesCircled';
import { adminStore } from '../../../stores/admin.store';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { cancelPreviousRequest } from '../../../utils/api.utils';
import { generateAssignmentErrorMessage } from '../../../utils/errorUtils';
import { getNameString } from '../../../utils/name.utils';
import { observer } from 'mobx-react-lite';
import { producerStateAssignments } from '../onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { useAuth } from '../../../auth/authProvider';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;
const tooltipMessages = {
  [ManageAssignmentsTab.ALL_AGENTS]:
    'Consists of both assigned and unassigned producers',
  [ManageAssignmentsTab.ASSIGNED_AGENTS]:
    'Producers with designated Territories and assigned states',
  [ManageAssignmentsTab.UNASSIGNED_AGENTS]:
    'Producers without assigned territories or states. Assign states or territories to them now',
};

const DEFAULT_SELECTED_FILTER = {
  'Assignment Status': {
    labelValuePair: {
      value: 'unassignedAgents',
      label: 'Unassigned Producers',
    },
    key: 'isAssigned',
  },
};

const DEFAULT_FILTER = [
  {
    operator: 'or',
    filters: [
      {
        key: 'isAssigned',
        condition: 'is',
        value: 'unassignedAgents',
      },
    ],
  },
];

function ManageAssignments() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // error toast setup
  const [api, contextHolder] = notification.useNotification();

  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };
  const [queryText, setQueryText] = useQueryState('search');
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [isLoading, setIsLoading] = useState(true);
  const [agentCount, setAgentCount] = useState(10);
  const { getAccessTokenSilently } = useAuth();
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isAssignmentsFetching, setIsAssignmentsFetching] = useState('');
  const [agents, setAgents] = useState<AssignmentAgent[]>([]);
  const [assignedStates, setAssignedStates] = useState<AssignedState[] | null>(
    null
  );
  const [sortValue, setSortValue] = useState({});
  const [selectedAgentIds, setSelectedAgentIds] = useState<string[]>([]);
  const [agentIdForAssignment, setAgentIdForAssignment] = useState<
    string | null
  >();
  const [searchText, setSearchText] = useState<string>('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [isAllUnassigned, setIsAllUnassigned] = useState<boolean>(false);

  const [refreshFilterOptions, setRefreshFetchFilter] =
    useState<boolean>(false);

  // Adding Filters
  const [requestBody, setRequestBody] = useState<Object>({});
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [agentHomeStates, setAgentHomeStates] = useState<string[]>([]);

  const onPageChange = (newPageNumber: number, newPageSize: number) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    loadAgents(queryText || '', newPageNumber, newPageSize);
  };

  const loadAgents = async (
    searchTextProp?: string,
    page?: number,
    size?: number,
    sort?: object
  ) => {
    setIsLoading(true);
    let filter = {};
    if (searchParams.has('overview')) {
      setSelectedFilters({
        data: DEFAULT_SELECTED_FILTER,
      } as unknown as FiltersType);
      filter = {
        filterGroups: DEFAULT_FILTER,
      };
      searchParams.delete('overview');
      setRequestBody({ ...requestBody, ...filter });
      setSearchParams(searchParams.toString(), { replace: true });
    }

    const token = await getAccessTokenSilently();
    const defaultRequestBody = {};
    const reqBody = {
      ...requestBody,
      ...defaultRequestBody,
      ...filter,
      ...(sort || sortValue || {}),
    };
    cancelToken && cancelPreviousRequest(cancelToken);
    const cancelTokenSource = axios.CancelToken.source();
    setCancelToken(cancelTokenSource);
    const response: any = await AgentService.getMyAgentsAssignments(
      page || pageNumber,
      size || pageSize,
      token,
      searchTextProp ? searchTextProp : queryText || '',
      undefined,
      cancelTokenSource,
      reqBody
    ).catch((error) => {
      if (!axios.isCancel(error)) {
        setCancelToken(undefined);
        setIsLoading(false);
      }
    });
    if (response && response.data) {
      setCancelToken(undefined);
      setAgents(response.data);
      setAgentCount(response.totalCount);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    assignedStates !== null && setModalVisibility(true);
  }, [assignedStates]);

  useEffect(() => {
    loadAgents();
  }, [adminStore.account, queryText]);

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  const handleBulkAssignStates = () => {
    selectedAgentIds.length > 0
      ? setAssignedStates([])
      : showError('Please select one or more agents');
  };

  const handleIndividualAssignStates = async (
    agentId: string,
    residentState: string,
    isCreate?: string
  ) => {
    setAgentIdForAssignment(agentId);

    setAgentHomeStates([residentState]);

    if (isCreate) {
      setModalVisibility(true);
      return;
    }

    setIsAssignmentsFetching(agentId);
    try {
      const token = await getAccessTokenSilently();
      const response: any =
        await AgentService.getLicenseConfigurationByProducerId(agentId, token);
      setAssignedStates(
        response?.data?.map((d: any) => {
          const liceseConfig = groupBy(
            d?.licenseConfigurations,
            'licenseClassCode'
          );
          return {
            stateGroupId: d?.territoryId || '',
            stateGroupName: d?.territoryName || null,
            stateCode: d?.stateCode,
            licenseConfigs: Object.values(liceseConfig)?.map((d) => {
              return {
                lcCode: d?.[0]?.licenseClassCode,
                loaCodes: d?.map((d) => d?.loaCode),
                loaids: d?.map((d) => d?._id || d?.id),
              };
            }),
          };
        }) || null
      );
      setModalVisibility(true);
    } catch (error) {
      console.error(error);
      message.error('Failed to get producer assignments.Please Try Again');
      setModalVisibility(false);
    } finally {
      setIsAssignmentsFetching('');
    }
  };

  const assignStateModalOnCloseCallback = (isCancel?: boolean) => {
    setAssignedStates(null);
    if (isCancel) return;
    if (agentIdForAssignment) {
      setAgentIdForAssignment(null);
      setAgentHomeStates([]);
    }
    setAssignedStates(null);
    loadAgents();
    setRefreshFetchFilter(true);
    setSelectedAgentIds([]);
  };

  const redirect = (text: string, record: AssignmentAgent) => {
    appStateInfoStore.setHeader(getNameString(text));
    navigate(RouteConstants.agentOverview.path.replace(':agentId', record.id));
  };

  const columns: ColumnsType<AssignmentAgent> = [
    {
      title: 'Producer',
      dataIndex: 'name',
      key: 'agentFullName',
      // sorter: false,
      render: (text: string, record: AssignmentAgent) => (
        <Typography.Paragraph
          style={{
            color: 'var(--primary-color)',
            textDecoration: 'none',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '0px',
          }}
          className="agent-link"
          key={`agent-row-${text}`}
        >
          <span
            className="cursor-pointer"
            onClick={() => {
              redirect(text, record);
            }}
          >
            {getNameString(text)}{' '}
          </span>
        </Typography.Paragraph>
      ),
    },
    {
      title: 'NPN',
      dataIndex: 'npn',
      // sorter: false,
      key: 'npn',
      render: (npn: string) => (
        <Typography.Paragraph
          style={{
            color: '#000000',
            textDecoration: 'none',
            fontSize: '14px',
            cursor: 'pointer',
            marginBottom: '0px',
            whiteSpace: 'nowrap',
          }}
        >
          {npn}
        </Typography.Paragraph>
      ),
    },
    {
      title: 'Assigned Territories',
      dataIndex: 'producerAgencyStateAssignments',
      key: 'producerAgencyStateAssignments',
      width: '200px',
      render: (assignedStates: producerStateAssignments[]) => {
        if (!assignedStates || !assignedStates?.length) return '-';
        const hashmap: { [key: string]: string } = {};
        assignedStates
          .filter((itr) => itr?.territoryId)
          .forEach((itr) => {
            if (itr?.territoryId)
              hashmap[itr?.territoryId] = itr.territoryName || '-';
          });
        return Object.keys(hashmap).length > 0 ? (
          <div style={{ display: 'flex' }}>
            {Object.entries(hashmap).map(([territoryId, territoryName]) => {
              const bgColor = stringToColor(territoryId);
              return (
                <Space size={[0, 8]} wrap key={territoryName}>
                  <Tag color={bgColor}>
                    <span
                      style={{
                        color: getTextColor(bgColor),
                      }}
                    >
                      {territoryName}
                    </span>
                  </Tag>
                </Space>
              );
            })}
          </div>
        ) : (
          <>-</>
        );
      },
    },
    {
      title: 'Assigned States',
      dataIndex: 'producerAgencyStateAssignments',
      key: 'producerAgencyStateAssignments',
      width: '500px',
      render: (assignedStates) => {
        if (!assignedStates || !assignedStates?.length) return '-';
        return (
          <Row style={{ flexWrap: 'wrap', rowGap: '5px' }}>
            {assignedStates.map((assignedState: producerStateAssignments) => {
              if (!assignedState.territoryId)
                return (
                  <StatesCircled
                    label={assignedState.stateCode}
                    key={assignedState.stateCode}
                    showTooltip={true}
                    tooltipTitle={`${getStateNameFromCode(
                      assignedState.stateCode
                    )}`}
                  />
                );
              else {
                const bgColor = stringToColor(assignedState.territoryId);
                return (
                  <StatesCircled
                    key={assignedState.stateCode}
                    backgroundColor={bgColor}
                    labelColor={getTextColor(bgColor)}
                    label={assignedState.stateCode}
                    showTooltip={true}
                    tooltipTitle={`${getStateNameFromCode(
                      assignedState.stateCode
                    )} (Inherited from : ${assignedState.territoryName})`}
                  />
                );
              }
            })}
          </Row>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      render: (id: string, record: AssignmentAgent) => (
        <Row justify="center">
          <Space>
            {!record?.producerAgencyStateAssignments ||
            record?.producerAgencyStateAssignments?.length === 0 ? (
              <div
                style={{
                  fontSize: '12px',
                  color: 'var(--hint-primary-color)',
                }}
              >
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: '#0588ca',
                    },
                  }}
                >
                  <Tooltip
                    title={
                      selectedAgentIds.length !== 0
                        ? 'Bulk assignment enabled'
                        : !record.residentState
                        ? 'Producer does not have a home state. Individual assignment is disabled.'
                        : 'Add Assignments'
                    }
                  >
                    <Button
                      id={
                        IdConstants.AGENTS.ASSIGNMENTS.TABLE
                          .MANAGE_ASSIGNMENTS_BUTTON +
                        '-' +
                        record.id
                      }
                      size="middle"
                      style={{
                        border: 'none',
                        boxShadow: 'none',
                        background: 'var(--hint-primary-color)',
                        color: 'white',
                        opacity:
                          selectedAgentIds.length !== 0 || !record.residentState
                            ? '0.6'
                            : '1',
                      }}
                      disabled={
                        selectedAgentIds.length !== 0 || !record.residentState
                      }
                      onClick={() => {
                        handleIndividualAssignStates(
                          record.id,
                          record.residentState,
                          'create'
                        );
                      }}
                    >
                      <PlusCircleOutlined />
                    </Button>
                  </Tooltip>
                </ConfigProvider>
              </div>
            ) : (
              <div
                style={{
                  fontSize: '12px',
                  color: 'var(--hint-primary-color)',
                }}
              >
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: '#0588ca',
                    },
                  }}
                >
                  {isAssignmentsFetching === record.id ? (
                    <Spin />
                  ) : (
                    <Tooltip
                      title={
                        record.isAssignmentProcessing
                          ? 'Cannot update assignment as last assignment update is still processing. Please refresh data and try again'
                          : 'Edit Assignments'
                      }
                    >
                      <Button
                        id={
                          IdConstants.AGENTS.ASSIGNMENTS.TABLE
                            .EDIT_ASSIGNMENT_BUTTON +
                          '-' +
                          record.id
                        }
                        size="middle"
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                          ...(!record.isAssignmentProcessing && {
                            background: 'var(--hint-primary-color)',
                            color: 'white',
                          }),
                          opacity: selectedAgentIds.length !== 0 ? '0.6' : '1',
                        }}
                        onClick={() => {
                          handleIndividualAssignStates(
                            record.id,
                            record.residentState
                          );
                        }}
                        disabled={
                          selectedAgentIds.length !== 0 ||
                          record.isAssignmentProcessing
                        }
                      >
                        <EditOutlined />
                      </Button>
                    </Tooltip>
                  )}
                </ConfigProvider>
              </div>
            )}
          </Space>
        </Row>
      ),
    },
  ];

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: AssignmentAgent[]
    ) => {
      const selectedStates = new Set(
        selectedRows.map((row) => row.residentState)
      );
      setAgentHomeStates(Array.from(selectedStates));
      setSelectedAgentIds(selectedRowKeys as string[]);
    },
    getCheckboxProps: (record: AssignmentAgent) => ({
      disabled: !record.residentState,
    }),
  };

  const fetchFiltersInfoForFindAll: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      const path = '/agency/assignments';
      setIsFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any = await AgentService.getFilterDetails(
          token,
          pageSize,
          pageNumber,
          path,
          searchText,
          {}
        );
        return response.data;
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups: [],
    };
    setRequestBody(updatedRequestBody);
  };

  useEffect(() => {
    if (Object.keys(requestBody).length) loadAgents('', 1);

    if (selectedFilters.data.hasOwnProperty('Assignment Status')) {
      const assignmentFilter = selectedFilters.data['Assignment Status'];
      if (!Array.isArray(assignmentFilter)) {
        const pair = assignmentFilter.labelValuePair;
        if (!Array.isArray(pair)) {
          if (
            pair.label === 'Unassigned Producers' ||
            pair.value === 'unassignedAgents'
          ) {
            setIsAllUnassigned(true);
          }
        }
      }
    } else {
      setSelectedAgentIds([]);
      setIsAllUnassigned(false);
    }
    setPageNumber(1);
  }, [requestBody]);
  const pageName = PlausiblePageConstants.AGENCY_ASSIGNMENTS;
  return (
    <Card style={{ width: '100%', height: 'fit-content' }}>
      {contextHolder}
      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        fetchFilters={() => fetchFiltersInfoForFindAll()}
        clearFilters={() => {
          clearFilters();
        }}
        refreshFetchFilter={refreshFilterOptions}
        setRefreshFetchFilter={setRefreshFetchFilter}
        pageName={pageName}
      />
      <Row
        justify="space-between"
        className="radio-buttons reset-icon-size"
        style={{ marginBottom: '10px' }}
      >
        <Typography.Text
          type="secondary"
          style={{ fontSize: '14px', marginBottom: 0 }}
        >
          <Space>
            <Tooltip
              title={
                tooltipMessages[
                  ManageAssignmentsTab.ALL_AGENTS as unknown as ManageAssignmentsTab
                ]
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
            View each producer and their assignments
          </Space>
        </Typography.Text>
        {isAllUnassigned && (
          <Button
            id="manage-assignments-assign-states-button"
            style={{
              background: 'var(--hint-primary-color)',
              color: 'white',
              opacity: selectedAgentIds.length === 0 ? '0.6' : '1',
            }}
            onClick={handleBulkAssignStates}
            disabled={isLoading || selectedAgentIds.length === 0}
          >
            Assign States
          </Button>
        )}
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          columnGap: 10,
          marginBottom: 10,
        }}
      >
        {/* <Search
          placeholder="Search by Producers, NPN"
          value={searchText}
          // allowClear
          disabled={isLoading}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onSearch={(value) => {
            if (queryText !== value) {
              setQueryText(value);
              setPageNumber(1);
            }
          }}
          onPressEnter={(e) => {
            if (queryText !== (e.target as HTMLInputElement).value) {
              setQueryText((e.target as HTMLInputElement).value);
              setPageNumber(1);
            }
          }}
        /> */}
        <FilterButton
          {...{
            filterInfoLoading,
            activeData: agents,
            requestBody,
            isLoading,
            setIsFilterVisible,
            pageName,
          }}
        />
      </div>
      <RenderFilterChip
        {...{ selectedFilters, removeFilter, clearFilters, pageName }}
      />
      <div>
        {!isLoading && isModalVisible && (
          <AssignModal
            agentIds={
              selectedAgentIds.length > 0
                ? selectedAgentIds
                : [agentIdForAssignment || '']
            }
            isLoading={!!isAssignmentsFetching}
            isModalVisible={isModalVisible}
            handleVisibility={setModalVisibility}
            currentAssignedStates={assignedStates ?? []}
            onClose={assignStateModalOnCloseCallback}
            emptyStateButton={true}
            homeState={agentHomeStates}
            responseCallback={(response) => {
              if (
                response?.data?.data?.errorResponses &&
                response?.data?.data?.errorResponses.length > 0
              ) {
                showError(generateAssignmentErrorMessage(response?.data?.data));
              }
            }}
          />
        )}

        <Row className="table" style={{ marginTop: '10px', rowGap: '10px' }}>
          <Col xs={24}>
            <Table
              rowKey={(record) => record.id}
              rowSelection={isAllUnassigned ? rowSelection : undefined}
              columns={columns}
              dataSource={agents}
              onChange={(pagination, filters, sorter) => {
                const sort = applySort(sorter, loadAgents);
                setSortValue(sort);
              }}
              pagination={false}
              loading={isLoading}
              scroll={{ x: 'max-content' }}
              rowClassName={(record) =>
                record.residentState ? '' : 'disabled-row'
              }
            />
          </Col>
          {!isEmpty(agents) && (
            <Col span={24}>
              <Row justify="end">
                <Pagination
                  pageSize={pageSize}
                  current={pageNumber}
                  total={agentCount}
                  onChange={onPageChange}
                  showSizeChanger
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  disabled={isLoading}
                />
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </Card>
  );
}

export default withRoles(observer(ManageAssignments), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
