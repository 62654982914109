import { AgentDocument } from '../types/response/data/agents/agentDocument.type';
import { CancelTokenSource } from 'axios';
import { ErrorResponse } from '../types/response/errorResponse.type';
import { InviteResponseEnum } from '../enums/invite-response.enum';
import { InviteStatus } from '../enums/inviteStatus.enum';
import { MessageSuccessResponse } from '../types/response/messageSuccessResponse.type';
import { SendInviteObject } from '../pages/agents/inviteDownline';
import { UrlConstants } from '../constants/url.constants';
import { apiStore } from '../stores/api.store';
import { filterRequestBody } from '../utils/common.utils';
import { getNameString } from '../utils/name.utils';
import { getProducerName } from '../pages/agents/agentsOverview/agencyAgentOverview';
import moment from 'moment';

export class DownlineInviteService {
  static host = UrlConstants.backend;

  /**
   * Callback to update acceptance of downline invitation
   * /downline-invite
   * @param inviteToken
   * @param response
   * @param token
   * @returns
   */
  static async updateResponseForAgentDownlineInviteOnSignup(
    inviteToken: string,
    response: InviteResponseEnum,
    token: string
  ): Promise<AgentDocument | null> {
    return new Promise(async (resolve, reject) => {
      const path = '/downline-invite';

      try {
        const resp = await apiStore
          .getApiClient(token)
          .patch(`${this.host}${path}/downline-agent-response`, {
            inviteToken,
            response,
          });
        resolve(resp.data);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * API called from invites page to accept / reject downline invite
   * @param inviteResponse
   * @param inviteToken
   * @param token
   * @returns
   */
  static async updateResponseForAgentDownlineInviteFromPortal(
    inviteToken: string,
    response: InviteResponseEnum,
    token: string
  ): Promise<AgentDocument | null> {
    return new Promise(async (resolve, reject) => {
      const path = '/downline-invite';

      try {
        const resp = await apiStore
          .getApiClient(token)
          .patch(`${this.host}${path}/downline-agent-response-portal`, {
            inviteToken,
            response,
          });
        resolve(resp.data);
      } catch (error: any) {
        console.error('AgentService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  static async postDownlineInvite(
    userInvite: SendInviteObject,
    bearerToken: string
  ) {
    const path = '/invite';
    return new Promise(async (resolve, reject) => {
      try {
        const createdAgent = await apiStore
          .getApiClient(bearerToken)
          .post(`${this.host}${path}`, userInvite, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          });
        resolve(createdAgent);
      } catch (error: any) {
        console.error(
          'DownlineInviteService :: postDownlineInvite :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Resend new admin invite
   * @param inviteId
   * @param senderUserId
   * @param invitedOn
   * @returns
   */
  static async postRenewDownlineInvite(
    agencyId: string,
    invitedOn: number,
    inviteToken: string,
    bearerToken: string
  ) {
    const path = `/downline-invite/${inviteToken}/renew`;
    return new Promise(async (resolve, reject) => {
      try {
        const createdAgent = await apiStore.getApiClient(bearerToken).post(
          `${this.host}${path}?resendInvite=true`,
          {
            adminId: agencyId,
            invitedOn: invitedOn,
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );
        resolve(createdAgent);
      } catch (error: any) {
        console.error(
          'DownlineInviteService :: postRenewDownlineInvite :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  static async revokeDownlineInvite(token: string, downlineInviteId: string) {
    const path = `/revoke`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .delete(`${this.host}/invite/${downlineInviteId}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });

        resolve(response.data);
      } catch (error: any) {
        console.error('revokeDownlineInvite Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Service Function to get Downline Invitation
   * @param size
   * @param page
   * @param token
   * @param searchString
   * @returns
   */
  static async getDownlineInvitation(
    size: number,
    page: number,
    token: string,
    searchString: string,
    requestBody: object,
    cancelTokenSource: CancelTokenSource
  ) {
    const path = `/invite/downline-invites`;
    const url_ = new URL(`${this.host}${path}`);
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());
    return new Promise(async (resolve, reject) => {
      // Process requestBody using filterRequestBody function
      filterRequestBody(requestBody, url_);

      // Should check why the request body is sent after the api is integrated - thirusha
      try {
        const response = await apiStore
          .getApiClient(token, cancelTokenSource)
          .get(`${url_}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });

        const downlineData =
          response.data.data &&
          response.data.data.map((downline: any) => {
            return {
              id: downline.id,
              email: downline.inviteeDetails.email,
              invitedUsing: downline.invitedUsing,
              invitedby: getNameString(downline.inviterDetails?.account?.name),
              invitedOn: moment(downline.invitedOn).format('MMMM DD, YYYY'),
              inviteToken: downline.inviteToken,
              isExpired: downline.isExpired,
            };
          });
        resolve({
          count: response.data.totalCount,
          downlines: downlineData,
        });
      } catch (error: any) {
        console.error('getDownlineInvitation Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Service Function to get Downline Invitation Filters
   * @param size
   * @param page
   * @param token
   * @param searchString
   * @returns
   */
  static async getDownlineInvitationFilters(token: string) {
    const path = `/invite/downline/filters`;
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(token)
          .get(`${this.host}${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'DownlineService :: getDownlineInvitationFilters :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Service to get Upline Agencies Invite
   * @param agencyId
   * @param size
   * @param page
   * @param token
   * @param searchString
   * @returns
   */
  static async getAgenciesUplineInvite(
    agencyId: string,
    size: number,
    page: number,
    token: string,
    searchString: string
  ) {
    const path = `/invite/upline-invites`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(
            `${
              this.host
            }${path}?size=${size}&page=${page}&searchString=${encodeURIComponent(
              searchString
            )}`,
            {
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );

        const uplineData =
          response.data.data &&
          response.data.data.map((upline: any) => {
            return {
              id: upline.id,
              name: upline.agency.name,
              npn: upline.npn,
              type: upline.uplineType,
              total_amount_allocated: upline.amountAllocated,
              expiresOn: moment(upline.expiresOn).format('MMMM DD, YYYY'),
              inviteToken: upline.inviteToken,
              invitedOn: moment(upline.createdAt).format('MMMM DD, YYYY'),
            };
          });
        resolve({
          count: response.data.totalCount,
          uplineData: uplineData,
        });
      } catch (error: any) {
        console.error('getAllAdmins Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Service to get Upline Producers Invite
   * @param agencyId
   * @param size
   * @param page
   * @param token
   * @param searchString
   * @returns
   */
  static async getAgentUplineInvite(
    agencyId: string,
    size: number,
    page: number,
    token: string,
    searchString: string
  ) {
    const path = `/downline-invite/agent-upline-invites-for-agency`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(
            `${
              this.host
            }${path}?agencyId=${agencyId}&size=${size}&page=${page}&searchString=${encodeURIComponent(
              searchString
            )}`,
            {
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );

        const uplineData =
          response.data.data &&
          response.data.data.map((upline: any) => {
            return {
              id: upline.id,
              name: upline.name,
              npn: upline.npn,
              type: upline.uplineType,
              total_amount_allocated: upline.amountAllocated,
              expiresOn: moment(upline.expiresOn).format('MMMM DD, YYYY'),
            };
          });
        resolve({
          count: response.data.totalCount,
          uplineData: uplineData,
        });
      } catch (error: any) {
        console.error('getAllAdmins Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Service to Review Upline Invite
   * @param uplineInviteId
   * @param token
   * @param reviewAccepted
   * @returns
   */
  static async updateInviteStatus(
    inviteToken: string,
    token: string,
    reviewAccepted: boolean
  ): Promise<MessageSuccessResponse> {
    const path = `/invite`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore.getApiClient(token).patch(
          `${this.host}${path}/${inviteToken}`,
          {
            status: reviewAccepted
              ? InviteStatus.ACCEPTED
              : InviteStatus.REJECTED,
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );

        resolve(response.data as MessageSuccessResponse);
      } catch (error: any) {
        console.error('revokeAdminAccess Error :: ', error?.message);
        reject(error.response.data as ErrorResponse);
      }
    });
  }

  static async rejectDownlineInviteOnSignup(
    inviteToken: string,
    token: string
  ): Promise<MessageSuccessResponse> {
    const path = '/downline-invite';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore.getApiClient(token).post(
          `${this.host}${path}/reject-downline-invite-signup`,
          {
            response: InviteResponseEnum.REJECTED,
            inviteToken: inviteToken,
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
        resolve(response.data as MessageSuccessResponse);
      } catch (error: any) {
        console.error('revokeAdminAccess Error :: ', error?.message);
        reject(error);
      }
    });
  }
}
