import {
  Button,
  Form,
  Pagination,
  Row,
  Skeleton,
  Table,
  Typography,
  notification,
} from 'antd';
import {
  CheckOutlined,
  ClockCircleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import {
  CsvJson,
  ScbReportBody,
  multipleOnboardStore,
} from '../../../../stores/multipleOnboardStore';
import {
  EMAIL_REGEX,
  LAST_NAME_REGEX,
  NPN_REGEX,
  SSN_REGEX,
} from '../../../../constants/regex.constants';
import {
  isErrorNoPaymentMethodsFound,
  isErrorSsnIsInvalid,
  isErrorStripeCustomerMissing,
  isErrorStripeOnboardingRequired,
  isErrorUnprocessedScbReport,
} from '../../../../utils/errorUtils';
import { useEffect, useState } from 'react';

import BulkOnboardCountConfirmModal from '../multipleOnboard/bulkOnboardCountConfirmModal';
import ContinueOnboardModal from '../multipleOnboard/continueOnboardModal';
import CsvPreviewModal from '../multipleOnboard/csvpreviewModal';
import { ErrorMessageConstants } from '../../../../constants/error-message.constants';
import LookUpModal from '../../../../utils/modal/mutiple-onboard/upload-csv/upload-csv.modal';
import { MultipleOnboardLookup } from '../../../../enums/onboardSteps.enum';
import { ScbReportService } from '../../../../services/scb-report.service';
import StripeOnboardingRequiredToast from '../../../../components/common/stripeOnboardingRequiredToast';
import TextWithIcon from '../../../../components/common/textWithIcon/textWithIcon';
import { getTimeDifference } from '../../../../utils/date/date-difference';
import { useAuth } from '../../../../auth/authProvider';
import { verifyCSV } from '../../../../enums/verifyCSV.enum';

interface NiprLookUpData {
  npn?: string;
  ssn?: string;
  lastName?: string;
  licenseNumber?: string;
  stateCode?: string;
  prefferredEmail?: string;
  name?: string;
}

interface RefreshCellProps {
  record: Report;
  disable: boolean;
  updateReport: (reportId: string, response: Object) => void;
}

function RefreshCell({ record, disable, updateReport }: RefreshCellProps) {
  const [pending, setPending] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const [api, contextHolder] = notification.useNotification();

  type NotificationType = 'success' | 'info' | 'warning' | 'error';
  const showAlert = (message: string, type: NotificationType) => {
    api[type]({
      message: 'Notification',
      description: message,
    });
  };

  const retrieveScbReport = async () => {
    try {
      setPending(true);
      const token = await getAccessTokenSilently();
      const response: any = await ScbReportService.retrieveScbReport(
        record.id,
        token
      );

      if (response.status === 'SUCCESS' && response.message) {
        showAlert(response.message, 'warning');
      }

      if (response.status === 'SUCCESS' && response.data) {
        updateReport(record.id, response?.data);
      }
      if (response.status !== 'SUCCESS') {
        showAlert('SomeThing went wrong please try again later', 'error');
      }
      setPending(false);
    } catch (err: any) {
      if (isErrorStripeCustomerMissing(err.response.data))
        showAlert(ErrorMessageConstants.STRIPE_CUSTOMER_ID_MISSING, 'error');
      else if (isErrorNoPaymentMethodsFound(err.response.data)) {
        showAlert(ErrorMessageConstants.NO_PAYMENT_METHODS_FOUND, 'error');
      } else if (isErrorUnprocessedScbReport(err.response.data))
        showAlert(err?.response.data.error?.message, 'error');
      else
        showAlert(
          'Please review the CSV file for any incorrect data and try uploading again.',
          'error'
        );

      setPending(false);
      console.error(err);
    }
  };

  return pending ? (
    <>
      {contextHolder}
      <Button style={{ width: '100px' }} loading={pending}></Button>
    </>
  ) : (
    <>
      {contextHolder}
      <Button
        disabled={disable}
        onClick={() => {
          retrieveScbReport();
        }}
      >
        <RedoOutlined />
        Refresh
      </Button>
    </>
  );
}

interface NiprBulkLookUpDto {
  npns: string[];
  niprBulkSsnLookUpDto: string[];
  niprBulkLicenseLookUpDto: string[];
}

interface Report {
  id: string;
  reportId: string;
  title: string;
  npnCount: number;
  niprBulkLookUpDto: NiprBulkLookUpDto;
  admin: string;
  agency: string;
  createdAt: string;
  niprResponseTimestamp: string;
  niprScbReportResponseDto: ScbReportBody;
  lastPollTimestamp: string;
  isOnboarded: boolean;
  csvJson: CsvJson[];
  isBeingProcessed: boolean;
  requestBody: any[];
  canBeOnboardedCount: number;
  cannotBeOnboardedCount: number;
}

interface OnboardDraftProps {
  handleNext: () => void;
}

function OnboardDraft({ handleNext }: OnboardDraftProps) {
  const [data, setData] = useState<Report[]>([]);
  const { getAccessTokenSilently } = useAuth();
  const [pageSize, setPageSize] = useState(10);
  const [isLookUpModalVisible, setLookUpModalVisible] = useState(false);
  const [
    isBulkOnboardCountConfirmModalVisible,
    setIsBulkOnboardCountConfirmModalVisible,
  ] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalError, setModalError] = useState('');
  const [form] = Form.useForm();
  const [uploadedFile, setUploadedFile] = useState<NiprLookUpData[]>();
  const [importAgentsSuccessful, setImportAgentsSuccessful] =
    useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const [previewModalVisibility, setPreviewModalVisibility] =
    useState<boolean>(false);
  const [previewModalData, setPreviewModalData] = useState<CsvJson[]>([]);
  const [onboardModalVisibility, setOnboardModalVisibility] =
    useState<boolean>(false);
  const [reportId, setReportId] = useState<string>('');

  type NotificationType = 'success' | 'info' | 'warning' | 'error';
  const showAlert = (
    message: string,
    type: NotificationType,
    heading: string
  ) => {
    api[type]({
      message: heading,
      description: message,
    });
  };

  const showError = (message: React.ReactNode) => {
    api['error']({
      message: 'Stripe Onboarding Incomplete',
      description: message,
    });
  };

  useEffect(() => {
    multipleOnboardStore.setMaxStep(0);
    multipleOnboardStore.setMinStep(0);
    fetchScbReports();
  }, []);

  const updateReport = (reportId: string, response: any) => {
    const reportToUpdate: any = data.findIndex(
      (report: Report) => report.id === reportId
    );
    if (reportToUpdate !== -1) {
      const newData: any = data.map((data, index) => {
        if (index === reportToUpdate) {
          return {
            ...data,
            isBeingProcessed: response?.isBeingProcessed,
            requestBody: response?.requestBody,
            canBeOnboardedCount: response?.canBeOnboardedCount,
            cannotBeOnboardedCount: response?.cannotBeOnboardedCount,
          };
        }
        return data;
      });
      setData(newData);
    }
  };
  const hasDuplicates = (array: any[]) => {
    const npnSet = new Set();
    const ssnSet = new Set();
    const emailSet = new Set();

    const generateDuplicateErrorMessage = (field: string, value: string) =>
      `One or more ${field} fields have duplicate in this batch. Ensure there are no duplicates.`;
    const generateInvalidErrorMessage = (field: string, value: string) =>
      `One or more ${field} fields are not valid in this batch. Please enter a valid ${field}`;

    for (const obj of array) {
      if (multipleOnboardStore.lookupMethod === MultipleOnboardLookup.NPN) {
        if (obj.npn) {
          if (npnSet.has(obj.npn)) {
            return generateDuplicateErrorMessage('NPN', obj.npn);
          }
          if (NPN_REGEX.test(obj.npn.trim())) {
            npnSet.add(obj.npn);
          } else {
            return generateInvalidErrorMessage('NPN', obj.npn);
          }
        }
      }
      if (obj.ssn) {
        if (ssnSet.has(obj.ssn)) {
          return generateDuplicateErrorMessage('SSN', obj.ssn);
        }
        if (SSN_REGEX.test(obj.ssn.trim())) {
          ssnSet.add(obj.ssn);
        } else {
          return generateInvalidErrorMessage('SSN', obj.ssn);
        }
      }
      if (multipleOnboardStore.lookupMethod === MultipleOnboardLookup.SSN) {
        if (obj.lastName) {
          if (!LAST_NAME_REGEX.test(obj.lastName.trim())) {
            return generateInvalidErrorMessage('last name', obj.lastName);
          }
        }
      }
      if (obj.email) {
        if (emailSet.has(obj.email)) {
          return generateDuplicateErrorMessage('Email', obj.email);
        }
        if (EMAIL_REGEX.test(obj.email)) {
          emailSet.add(obj.email);
        } else {
          return generateInvalidErrorMessage('Email', obj.email);
        }
      }
    }
    return verifyCSV.NO_DUPLICATE;
  };

  const onFinish = async (values: any) => {
    try {
      const token = await getAccessTokenSilently();
      if (uploadedFile) {
        setModalLoading(true);
        const filteredFileContent = uploadedFile.filter(
          (obj) => Object.keys(obj).length > 1
        );
        if (filteredFileContent) {
          const duplicatedValue = hasDuplicates(filteredFileContent);
          if (duplicatedValue !== verifyCSV.NO_DUPLICATE) {
            showAlert(`${duplicatedValue}`, 'error', 'ERROR');
            setModalLoading(false);
            return;
          }
          const response: any = await ScbReportService.bulkNpnLookUp(
            filteredFileContent,
            token
          );
          if (response.status === 'SUCCESS') {
            showAlert('CSV Uploaded SuccessFully', 'success', 'SUCCESS');
            setLookUpModalVisible(false);
          } else {
            showAlert('CSV Upload Failed , Try Again', 'error', 'ERROR');
            setLookUpModalVisible(false);
          }
          setModalLoading(false);
          fetchScbReports();
        }
        form.resetFields();
        multipleOnboardStore.setIsFileUploaded(false);
        multipleOnboardStore.setLookupMethod(MultipleOnboardLookup.SSN);
        multipleOnboardStore.setUploadFileName('');
        multipleOnboardStore.resetMultipleOnboard();
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        if (isErrorStripeOnboardingRequired(error.response.data))
          showError(<StripeOnboardingRequiredToast />);
        else if (isErrorStripeCustomerMissing(error.response.data))
          showError(ErrorMessageConstants.STRIPE_CUSTOMER_ID_MISSING);
        else if (isErrorNoPaymentMethodsFound(error.response.data))
          showError(ErrorMessageConstants.NO_PAYMENT_METHODS_FOUND);
        else if (isErrorSsnIsInvalid(error.response.data))
          showError(ErrorMessageConstants.INVALID_SSNS);
        else
          showAlert(
            'Some fields in the uploaded CSV are missing. Please review and update the values accordingly before uploading again. You can refer to the sample CSV for the correct format if needed',
            'error',
            'ERROR'
          );
      } else {
        showAlert(
          'Something went wrong, please try again after some time.',
          'error',
          'ERROR'
        );
      }
      setModalLoading(false);
      console.error('Error:', error?.response?.data?.error?.message);
    } finally {
    }
  };

  const fetchScbReports = async (
    pageNumberprops?: number,
    pageSizeprops?: number
  ) => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const response: any = await ScbReportService.getAllScbReport(
        pageNumberprops || pageNumber,
        pageSizeprops || pageSize,
        token
      );
      setData(response.data);
      setTotalCount(response.totalCount);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const onPageChange = (newPageNumber: number, newPageSize: number) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchScbReports(newPageNumber, newPageSize);
  };

  const columns = [
    // {
    //   title: 'Report Id',
    //   dataIndex: 'reportId',
    //   width: '25%',
    // },
    {
      title: 'Time Stamp',
      dataIndex: 'createdAt',
      render: (date: string, record: Report) => {
        return getTimeDifference(date);
      },
    },
    {
      title: 'No. of agents',
      dataIndex: 'npnCount',
    },
    // {
    //   title: 'Last Poll',
    //   dataIndex: 'lastPollTimestamp',
    //   render: (date: string, record: Report) => {
    //     return getTimeDifference(date);
    //   },
    // },
    {
      title: 'Status',
      dataIndex: 'isBeingProcessed',

      render: (processing: boolean, record: Report) => {
        return (
          <div>
            {processing ? (
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}
              >
                <ClockCircleOutlined style={{ color: '#F79E1B' }} />
                <p style={{ color: '#F79E1B' }}>NIPR Status Pending</p>
              </div>
            ) : (
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}
              >
                <CheckOutlined style={{ color: '#0A1' }} />
                <p style={{ color: '#0A1' }}>Success</p>
              </div>
            )}
          </div>
        );
      },
    },
    {
      render: (_: any, record: Report) => {
        return (
          <RefreshCell
            record={record}
            disable={record.niprScbReportResponseDto ? true : false}
            updateReport={updateReport}
          />
        );
      },
    },
    {
      render: (_: any, record: Report) => {
        return (
          <Button
            disabled={record.isBeingProcessed || record.isOnboarded}
            onClick={() => {
              // record.niprScbReportResponseDto.csvJson = record.requestBody;
              multipleOnboardStore.setCanBeOnboardCount(
                record.canBeOnboardedCount
              );
              multipleOnboardStore.onBoardErrorCSV = record.requestBody.filter(
                (request: any) => request?.errorCondition?.length
              );
              multipleOnboardStore.cannotBeOnboardedCount =
                record.cannotBeOnboardedCount;

              multipleOnboardStore.bulkOnboardId = record.id;

              // multipleOnboardStore.setAgentWithValidNiprResponse(
              //   record.requestBody
              // );
              setPreviewModalData(record.requestBody);
              setReportId(record.reportId);
              setOnboardModalVisibility(true);
            }}
          >
            Continue Onboarding
          </Button>
        );
      },
    },

    {
      title: 'View Preview',
      dataIndex: 'csvJson',
      render: (date: string, record: Report) => {
        return (
          <Button
            size="middle"
            // shape="circle"
            className="button primary-button ml-auto"
            onClick={() => {
              setPreviewModalData(record.requestBody);
              setPreviewModalVisibility(true);
            }}
          >
            <EyeOutlined />
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <div>
          <Typography.Paragraph
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: 'var(--secondary-color)',
            }}
          >
            CSV Upload
          </Typography.Paragraph>
          <Typography.Paragraph
            style={{
              marginTop: '-15px',
            }}
            className="reset-icon-size"
          >
            <TextWithIcon
              iconColor="#97BFBF"
              textStyle={{
                fontSize: '14px',
                fontWeight: 500,
                color: '#97BFBF',
              }}
              text={`CSV upload may take some time to verify and fetch all producer details. Please wait and use the refresh option to obtain the latest updates from NIPR.`}
              iconAlignment="left"
              icon={<InfoCircleOutlined />}
            />
          </Typography.Paragraph>
        </div>
        <Button
          style={{ marginTop: '10px' }}
          className={isLoading ? '' : 'button secondary-button'}
          size="middle"
          disabled={isLoading}
          onClick={() => {
            setLookUpModalVisible(true);
          }}
        >
          Upload CSV
        </Button>
      </div>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Table dataSource={data} columns={columns} pagination={false} />
          <Row justify="end" style={{ marginTop: 10 }}>
            <Pagination
              total={totalCount}
              pageSize={pageSize}
              current={pageNumber}
              showSizeChanger
              onChange={onPageChange}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
            />
          </Row>
        </>
      )}
      {isLookUpModalVisible && (
        <LookUpModal
          visible={isLookUpModalVisible}
          setVisible={setLookUpModalVisible}
          error={modalError}
          form={form}
          setError={setModalError}
          onFinish={onFinish}
          isLoading={modalLoading}
          setUploadedFile={setUploadedFile}
          setImportAgentsSuccessful={setImportAgentsSuccessful}
          importAgentsSuccessful={importAgentsSuccessful}
          setIsBulkOnboardCountConfirmModalVisible={
            setIsBulkOnboardCountConfirmModalVisible
          }
        />
      )}
      {previewModalVisibility && (
        <CsvPreviewModal
          csvJson={previewModalData}
          modalVisibility={previewModalVisibility}
          setModalVisibility={setPreviewModalVisibility}
        />
      )}
      {onboardModalVisibility && (
        <ContinueOnboardModal
          CsvJson={previewModalData}
          modalVisibility={onboardModalVisibility}
          setModalVisibility={setOnboardModalVisibility}
          reportId={reportId}
          handleNext={handleNext}
        />
      )}
      {isBulkOnboardCountConfirmModalVisible && (
        <BulkOnboardCountConfirmModal
          modalVisibility={isBulkOnboardCountConfirmModalVisible}
          setModalVisibility={setIsBulkOnboardCountConfirmModalVisible}
          handleNext={() => setIsBulkOnboardCountConfirmModalVisible(false)}
          handleCancel={() => {
            form.resetFields();
            setIsBulkOnboardCountConfirmModalVisible(false);
            setLookUpModalVisible(false);
            multipleOnboardStore.setIsFileUploaded(false);
            multipleOnboardStore.resetMultipleOnboard();
          }}
        />
      )}
    </div>
  );
}

export default OnboardDraft;
