import { Button, Col, Layout, Row, Typography } from 'antd';
import { ReactNode, useEffect, useState } from 'react';

import { IdConstants } from '../../constants/id.constants';
import { LogoutOutlined } from '@ant-design/icons';
import { ReferralConfig } from '../../types/data/referralConfig.type';
import { RouteConstants } from '../../constants/routes.constants';
import { SignupService } from '../../services/signup.service';
import featuresImage from '../../assets/images/onboarding/insuretrek-onboarding-features.png';
import { getQueryParams } from '../../utils/queryParams.utils';
import leftPanelImage from '../../assets/images/onboarding/insuretrek-left-panel.png';
import logo from '../../assets/icons/insuretrek-logo-white.png';
import { useAuth } from '../../auth/authProvider';
import { useLocation } from 'react-router';

type Props = {
  flowType?:
    | 'SignIn'
    | 'SignUp'
    | 'MagicLink'
    | 'ForgotPassword'
    | 'Onboarding';
  step?: number;
  children?: ReactNode;
};
export const OnboardingLayout = ({
  flowType = 'SignIn',
  step = 1,
  children,
}: Props) => {
  const { performLogout, isAuthenticated } = useAuth();
  const queryParams = getQueryParams();
  const location = useLocation();
  const [title, setTitle] = useState(
    flowType === 'SignUp' || flowType === 'Onboarding'
      ? "Let's Get Started!"
      : flowType === 'ForgotPassword'
      ? 'Forgot Password?'
      : 'Welcome Back!'
  );
  const [subtitle, setSubtitle] = useState(
    'Insuretrek helps you streamline your Health & Life producer licensing, and keep your whole team up to date.'
  );
  const [hideLogoutButton, setHideLogoutButton] = useState(false);

  useEffect(() => {
    setHideLogoutButton(location.pathname === RouteConstants.rootRedirect.path);
  }, [location]);

  useEffect(() => {
    if (queryParams['ref'] && flowType !== 'ForgotPassword') {
      SignupService.fetchReferralConfig(queryParams['ref'])
        .then((data: ReferralConfig) => {
          if(data){
          setTitle(data.title);
          setSubtitle(data.subtitle);}
        })
        .catch((error) => {
          console.log(
            'Error while fetching referral config : ',
            queryParams['ref']
          );
        });
    }
  }, []);

  return (
    <Layout>
      <Row
        justify="center"
        align="top"
        style={{ height: '100vh', backgroundColor: 'white' }}
      >
        <Col
          lg={24}
          xl={12}
          style={{
            height: window.innerWidth > 1024 ? '100%' : '50%',
            overflowY: 'hidden',
            backgroundImage: `url(${leftPanelImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div
            style={{
              flexDirection: 'column',
              padding: step === 1 ? '0px 100px 50px' : '0px 0px 75px 0px',
            }}
          >
            {step === 1 ? (
              <>
                <img alt="logo" src={logo} />
                <Typography.Title style={{ color: 'white' }}>
                  {title}
                </Typography.Title>
                {flowType !== 'ForgotPassword' && (
                  <Typography.Paragraph
                    style={{ color: 'var(--primary-color)', fontSize: 18 }}
                  >
                    {subtitle}
                  </Typography.Paragraph>
                )}
              </>
            ) : (
              <img src={featuresImage} alt="Features" />
            )}
          </div>
        </Col>
        <Col
          lg={24}
          xl={12}
          style={{
            height: window.innerWidth > 1024 ? '100%' : '50%',
            overflowY: 'auto',
          }}
        >
          <div
            style={{
              padding: '44px 44px 88px 44px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100%',
              rowGap: 24,
            }}
          >
            {!hideLogoutButton && isAuthenticated() && (
              <Button
                id={IdConstants.SIGNUPFLOW.LOGOUT_BUTTON}
                type="primary"
                className="button important-action-button"
                style={{ alignSelf: 'end' }}
                onClick={() => {
                  performLogout(window.location.origin);
                }}
                icon={<LogoutOutlined />}
              >
                Logout
              </Button>
            )}
            {children}
          </div>
        </Col>
      </Row>
    </Layout>
  );
};
