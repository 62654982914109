import { Card, ConfigProvider, Switch, Tooltip, Typography } from 'antd';
import { ReactNode, useEffect, useState } from 'react';

import { AgentBiographicInfo } from '../../../../components/agentBiographicInfo/agentBiographicInfo';
import { IdConstants } from '../../../../constants/id.constants';
import { PayeeEnum } from '../../../../enums/payee.enum';
import PaymentConfigModal from '../../../../components/paymentConfig/paymentConfigModal';
import { PaymentTypeEnum } from '../../../../enums/paymentType.enum';
import { ProducerDetails } from '../../onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import { adminStore } from '../../../../stores/admin.store';
import { costFormatter } from '../../../../utils/input.utils';
import { getProducerName } from '../agencyAgentOverview';
import penIcon from '../../../../assets/icons/pen.svg';

type PaymentProps = {
  agent: ProducerDetails | undefined;
};

export const TooltipWrapper = ({
  isDisabled,
  children,
  message = '',
}: {
  isDisabled: boolean;
  children: ReactNode;
  message?: string;
}) => {
  if (!isDisabled) return <>{children}</>;
  return (
    <Tooltip title={message || 'Auto Apply cannot be modified'}>
      {children}
    </Tooltip>
  );
};

function PaymentConfigurations(props: PaymentProps) {
  const [paymentConfigModalVisibility, setPaymentConfigModalVisibility] =
    useState(false);
  const [agent, setAgent] = useState(props?.agent);

  const updatePaymentConfigModal = (
    renewalPaymentAccount: PayeeEnum,
    initialPayment: PayeeEnum,
    paymentType: PaymentTypeEnum,
    autoRenewal: boolean
  ) => {
    setAgent((prevAgent) => {
      if (!prevAgent) return prevAgent;
      return {
        ...prevAgent,
        paymentConfig: {
          ...prevAgent.paymentConfig,
          licenseRenewalPayee: renewalPaymentAccount,
          paymentType: paymentType,
          licenseInitialPayee: initialPayment,
          isAutoRenewalActive: autoRenewal,
        },
      };
    });
  };
  useEffect(() => {
    if (
      !(
        agent?.paymentConfig?.licenseInitialPayee === PayeeEnum.AGENCY ||
        agent?.paymentConfig?.licenseRenewalPayee === PayeeEnum.AGENCY
      )
    ) {
      setAgent((prevAgent) => {
        if (!prevAgent) return prevAgent;
        return {
          ...prevAgent,
          paymentConfig: {
            ...prevAgent.paymentConfig,
            paymentType: PaymentTypeEnum.NA,
          },
        };
      });
    } else {
      setAgent((prevAgent) => {
        if (!prevAgent) return prevAgent;
        return {
          ...prevAgent,
          paymentConfig: {
            ...prevAgent.paymentConfig,
            paymentType: PaymentTypeEnum.FULL,
          },
        };
      });
    }
  }, [
    agent?.paymentConfig?.licenseInitialPayee,
    agent?.paymentConfig?.licenseRenewalPayee,
  ]);

  // as capped amount is removed now, so payment type is always full
  // if (agent?.agencyId?.id === adminStore.agency?.id) {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          marginTop: '20px',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            width: '45%',
          }}
        >
          <Typography.Paragraph style={{ fontSize: '14px', fontWeight: 500 }}>
            Initial payment account
          </Typography.Paragraph>
          <div
            onClick={() => {
              setPaymentConfigModalVisibility(true);
            }}
            style={{
              width: '98%',
              fontSize: '14px',
              display: 'flex',
            }}
          >
            <img
              src={penIcon}
              style={{
                marginRight: '10px',
                marginTop: '5px',
                cursor: 'pointer',
              }}
              alt="pen"
              onClick={() => {
                setPaymentConfigModalVisibility(true);
              }}
            />
            <span style={{ textTransform: 'capitalize' }}>
              {agent?.paymentConfig?.licenseInitialPayee ? (
                getProducerName(agent?.paymentConfig?.licenseInitialPayee)
              ) : (
                <span style={{ color: '#92A69E', fontSize: '12px' }}>
                  Select Initial payment account
                </span>
              )}{' '}
            </span>
          </div>
        </div>
        <div
          style={{
            width: '45%',
          }}
        >
          <Typography.Paragraph style={{ fontSize: '14px', fontWeight: 500 }}>
            Renewal Payment Account
          </Typography.Paragraph>
          <div
            onClick={() => {
              setPaymentConfigModalVisibility(true);
            }}
            style={{
              width: '98%',
              fontSize: '14px',
              display: 'flex',
            }}
          >
            <img
              src={penIcon}
              style={{
                marginRight: '10px',
                marginTop: '5px',
                cursor: 'pointer',
              }}
              alt="pen"
              onClick={() => {
                setPaymentConfigModalVisibility(true);
              }}
            />
            <span style={{ textTransform: 'capitalize' }}>
              {agent?.paymentConfig?.licenseRenewalPayee ? (
                getProducerName(agent?.paymentConfig?.licenseRenewalPayee)
              ) : (
                <span style={{ color: '#92A69E', fontSize: '12px' }}>
                  Select Renewal Payment
                </span>
              )}
            </span>
          </div>
        </div>
      </div>

      <Card style={{ marginTop: '30px' }}>
        <Typography.Paragraph style={{ fontSize: '14px', fontWeight: 500 }}>
          Setup Auto Apply
        </Typography.Paragraph>
        <hr className="secondary-line" />
        <div style={{ display: 'flex', marginTop: '20px' }}>
          <div
            style={{
              display: 'flex',
              marginRight: '20px',
              marginTop:
                agent?.paymentConfig?.licenseRenewalPayee !== PayeeEnum.AGENT
                  ? '20px'
                  : '0',
              opacity:
                agent?.paymentConfig?.licenseRenewalPayee !== PayeeEnum.AGENT
                  ? '100%'
                  : '50%',
            }}
          >
            <Typography.Paragraph
              style={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '30px',
              }}
            >
              Auto Apply
            </Typography.Paragraph>
            <ConfigProvider theme={{ token: { colorPrimary: '#0588ca' } }}>
              <TooltipWrapper
                isDisabled={
                  agent?.paymentConfig?.licenseRenewalPayee !==
                    PayeeEnum.AGENCY ||
                  (agent?.paymentConfig?.paymentType !== PaymentTypeEnum.CAP &&
                    agent?.paymentConfig?.paymentType !== PaymentTypeEnum.FULL)
                }
                message={
                  !agent?.paymentConfig?.licenseRenewalPayee &&
                  !agent?.paymentConfig?.paymentType
                    ? 'Auto Apply cannot be modified as Renewal Type and Payment Configuration are not set.'
                    : agent?.paymentConfig?.licenseRenewalPayee ===
                      PayeeEnum.AGENT
                    ? 'Auto Apply cannot be modified as Renewal Type is set to Producer'
                    : agent?.paymentConfig?.paymentType !==
                        PaymentTypeEnum.CAP &&
                      agent?.paymentConfig?.paymentType !== PaymentTypeEnum.FULL
                    ? 'Auto Apply cannot be modified as Payment Configuration is not specified.'
                    : 'Auto Apply cannot be modified .'
                }
              >
                <Switch
                  id={IdConstants.SWITCH_BUTTON + '-auto-renewal'}
                  disabled={
                    agent?.paymentConfig?.licenseRenewalPayee !==
                      PayeeEnum.AGENCY ||
                    (agent?.paymentConfig?.paymentType !==
                      PaymentTypeEnum.CAP &&
                      agent?.paymentConfig?.paymentType !==
                        PaymentTypeEnum.FULL)
                  }
                  onClick={() => {
                    setPaymentConfigModalVisibility(true);
                  }}
                  checked={
                    agent?.paymentConfig?.isAutoRenewalActive
                      ? agent?.paymentConfig?.isAutoRenewalActive
                      : false
                  }
                />
              </TooltipWrapper>
            </ConfigProvider>
          </div>
          <div>
            {agent?.paymentConfig?.licenseRenewalPayee !== PayeeEnum.AGENT && (
              <Typography.Paragraph
                style={{
                  fontSize: '14px',
                  fontWeight: 700,
                  color: 'var(--primary-color)',
                }}
              >
                Auto Apply is{' '}
                {agent?.paymentConfig?.isAutoRenewalActive ? 'On' : 'Off'}!
              </Typography.Paragraph>
            )}

            <Typography.Paragraph style={{ fontSize: '14px', fontWeight: 500 }}>
              {agent?.paymentConfig?.licenseRenewalPayee !== PayeeEnum.AGENT
                ? ' All the assigned state licenses will be applied automatically for this producer.'
                : 'Producer will have to configure their auto apply settings'}
            </Typography.Paragraph>
          </div>
        </div>
      </Card>

      <PaymentConfigModal
        agents={agent && [agent]}
        visible={paymentConfigModalVisibility}
        handleVisibility={() => {
          setPaymentConfigModalVisibility(false);
        }}
        setAgents={updatePaymentConfigModal}
      />
    </div>
  );
  // } else {
  // return (
  //   <div>
  //     <div
  //       style={{
  //         marginTop: '20px',
  //         display: 'flex',
  //         justifyContent: 'space-between',
  //         width: '50%',
  //       }}
  //     >
  //       <div>
  //         <Typography.Paragraph
  //           style={{
  //             fontSize: '12px',
  //             fontWeight: 400,
  //             color: 'var(--secondary-color)',
  //           }}
  //         >
  //           Amount Spent
  //         </Typography.Paragraph>
  //         <Typography.Paragraph
  //           style={{
  //             fontSize: '16px',
  //             fontWeight: 400,
  //           }}
  //         >
  //           {<span>$ {agent?.selfAmountUsed}</span>}
  //         </Typography.Paragraph>
  //       </div>
  //       <div>
  //         <Typography.Paragraph
  //           style={{
  //             fontSize: '12px',
  //             fontWeight: 400,
  //             color: 'var(--secondary-color)',
  //           }}
  //         >
  //           Amount Left
  //         </Typography.Paragraph>
  //         <Typography.Paragraph
  //           style={{
  //             fontSize: '16px',
  //             fontWeight: 400,
  //           }}
  //         >
  //           {
  //             <span>
  //               ${' '}
  //               {agent?.paymentConfig?.amountCap
  //                 ? agent.paymentConfig?.amountCap === -1
  //                   ? 0
  //                   : costFormatter(
  //                       agent?.paymentConfig?.amountCap -
  //                         agent?.parentAmountUsed
  //                     )
  //                 : 0}
  //             </span>
  //           }
  //         </Typography.Paragraph>
  //       </div>
  //     </div>
  //     <hr className="primary-line" style={{ marginTop: '20px' }} />

  //     <div
  //       style={{
  //         display: 'flex',
  //         marginTop: '20px',
  //         justifyContent: 'space-between',
  //       }}
  //     >
  //       <div
  //         style={{
  //           width: '45%',
  //         }}
  //       >
  //         <Typography.Paragraph style={{ fontSize: '14px', fontWeight: 500 }}>
  //           Initial payment account
  //         </Typography.Paragraph>
  //         <div
  //           style={{
  //             width: '98%',
  //             fontSize: '14px',
  //             display: 'flex',
  //             justifyContent: 'space-between',
  //           }}
  //         >
  //           <span
  //             style={{
  //               fontSize: '16px',
  //             }}
  //           >
  //             {agent?.paymentConfig?.initialPaymentPayee ? (
  //               getProducerName(agent?.paymentConfig?.initialPaymentPayee)
  //             ) : (
  //               <span style={{ color: '#92A69E', fontSize: '12px' }}>
  //                 Initial Payment not configured
  //               </span>
  //             )}{' '}
  //           </span>
  //         </div>
  //       </div>
  //       <div
  //         style={{
  //           width: '45%',
  //         }}
  //       >
  //         <Typography.Paragraph style={{ fontSize: '14px', fontWeight: 500 }}>
  //           Renewal Payment
  //         </Typography.Paragraph>
  //         <div
  //           style={{
  //             width: '98%',
  //             fontSize: '14px',
  //             display: 'flex',
  //             justifyContent: 'space-between',
  //           }}
  //         >
  //           <span
  //             style={{
  //               fontSize: '16px',
  //             }}
  //           >
  //             {agent?.paymentConfig?.renewalPaymentPayee ? (
  //               agent?.paymentConfig?.renewalPaymentPayee
  //             ) : (
  //               <span style={{ color: '#92A69E', fontSize: '12px' }}>
  //                 Select Renewal Payment
  //               </span>
  //             )}
  //           </span>
  //         </div>
  //       </div>
  //     </div>

  //     <Card style={{ marginTop: '30px' }}>
  //       <div style={{ display: 'flex', marginTop: '20px' }}>
  //         <div
  //           style={{
  //             display: 'flex',
  //             marginRight: '20px',
  //             marginTop:
  //               agent?.paymentConfig?.renewalPaymentPayee !== 'Agent'
  //                 ? '20px'
  //                 : '0',
  //             opacity:
  //               agent?.paymentConfig?.renewalPaymentPayee !== 'Agent'
  //                 ? '100%'
  //                 : '50%',
  //           }}
  //         >
  //           <Typography.Paragraph
  //             style={{
  //               fontSize: '16px',
  //               fontWeight: 500,
  //               marginRight: '30px',
  //             }}
  //           >
  //             Auto Apply
  //           </Typography.Paragraph>
  //           <ConfigProvider theme={{ token: { colorPrimary: '#0588ca' } }}>
  //             <Switch
  //               disabled={true}
  //               checked={
  //                 agent?.autoRenewEnabled ? agent?.autoRenewEnabled : false
  //               }
  //             />
  //           </ConfigProvider>
  //         </div>
  //         <div>
  //           {agent?.paymentConfig?.renewalPaymentPayee !== 'Agent' && (
  //             <Typography.Paragraph
  //               style={{
  //                 fontSize: '16px',
  //                 fontWeight: 700,
  //                 color: 'var(--primary-color)',
  //               }}
  //             >
  //               Auto Apply is {agent?.autoRenewEnabled ? 'On' : 'Off'}!
  //             </Typography.Paragraph>
  //           )}

  //           <Typography.Paragraph
  //             style={{ fontSize: '16px', fontWeight: 500 }}
  //           >
  //             {agent?.paymentConfig?.renewalPaymentPayee !== 'Agent'
  //               ? ' All the assigned state licenses will be applied automatically for this producer.'
  //               : 'Producer will have to configure their auto apply settings'}
  //           </Typography.Paragraph>
  //         </div>
  //       </div>
  //     </Card>
  //   </div>
  // );
  // }
}

export default PaymentConfigurations;
