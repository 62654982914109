import { appStateInfoStore } from '../stores/appStateInfo.store';

export const getLcandLoaCodeFromStateCode = (stateCode: string) => {
  const stateData = appStateInfoStore
    .getLicenseConfigsMasterData()
    .find((data) => data.stateCode === stateCode);
  if (stateData) {
    return stateData.licenseClasses.map((lc) => ({
      lcCode: lc.lcCode,
      loaCode: lc.loaData.map((loa) => loa.loaCode),
      loaids: lc.loaData.map((loa) => loa.id || ''),
    }));
  }
};
