import { AddressDetails } from '../types/data/addressDetails.type';
import { AgencyService } from '../services/agency.service';
import { makeAutoObservable } from 'mobx';

export interface AgencyDetails {
  agencyName: string;
  npn: string;
  fein: string;
  homeState: string;
}

export interface AgencyAddress {
  businessAddress: AddressDetails;
  mailingAddress: AddressDetails;
  residenceAddress: AddressDetails;
  email: string;
  phone: string;
  fax: string;
}

export interface ProfileInfo {
  name: string;
  email: string;
  role: string;
  npn: string;
}

class AgencyProfileStore {
  agencyDetails: AgencyDetails = {
    agencyName: '',
    npn: '',
    fein: '',
    homeState: '',
  };

  agencyAddresses: AgencyAddress = {
    businessAddress: {
      dateUpdated: '02/09/2023',
      addressTypeCode: '2',
      addressType: 'Business',
      addressLine1: '4175 Main St',
      addressLine2: '',
      addressLine3: '',
      city: 'Pittsboro',
      state: 'NC',
      zip: '27312',
      country: 'U.S.A.',
    },
    mailingAddress: {
      dateUpdated: '02/09/2023',
      addressTypeCode: '3',
      addressType: 'Mailing',
      addressLine1: '97 Laurel Bowie Rd',
      addressLine2: '',
      addressLine3: '',
      city: 'Burlington',
      state: 'NC',
      zip: '27215',
      country: 'U.S.A.',
    },
    residenceAddress: {
      dateUpdated: '02/09/2023',
      addressTypeCode: '1',
      addressType: 'Residence',
      addressLine1: '23696 Commerce Center Dr',
      addressLine2: '',
      addressLine3: '',
      city: 'Burlington',
      state: 'NC',
      zip: '27217',
      country: 'U.S.A.',
    },
    email: 'agency@somedomain.com',
    phone: '(212)-456-7890',
    fax: '(212)-456-7890',
  };

  constructor() {
    makeAutoObservable(this);
  }

  profileInfo: ProfileInfo = {
    name: '',
    email: '',
    npn: '',
    role: '',
  };

  fetchNpn() {
    if (this.profileInfo.email) {
      return '3432332';
    }
    return '0';
  }

  //getters
  getAgencyDetails = () => this.agencyDetails;
  getAgencyAddress = () => this.agencyAddresses;
  getProfileInfo = () => this.profileInfo;

  loadAgencyDetails = async (token: string) => {
    const resp = await AgencyService.getMyAgencyInfo(token).catch((e) =>
      console.error(e)
    );
    if (resp?.data?.fein) this.setFein(resp.data.fein);
    if (resp?.data?.residentStateCode)
      this.setHomeState(resp.data.residentStateCode);
  };

  setFein = (fein: string) => {
    this.agencyDetails.fein = fein;
  };

  setHomeState = (homeState: string) => {
    this.agencyDetails.homeState = homeState;
  };

  //setters
  setAgencyAddress = () => {};
  setProfileInfo = (name: string, email: string) => {
    this.profileInfo.name = name;
    this.profileInfo.email = email;
  };
  setProfileRole = (role: string) => {
    this.profileInfo.role = role;
  };
  setProfileNpn = (npn: string) => {
    this.profileInfo.npn = npn;
  };
}

export const agencyProfileStore = new AgencyProfileStore();
