import { InsureTrekAccount, InsureTrekAgency } from './auth0Store';

import { AdminInvitesService } from '../services/adminInvites.service';
import { AdminService } from '../services/admin.service';
import { AllAdmins_ } from '../types/data/allAdmins';
import { CancelTokenSource } from 'axios';
import { getNameString } from '../utils/name.utils';
import { makeAutoObservable } from 'mobx';
import moment from 'moment';

export interface AdminState {
  _id: string;
  firstName: string;
  lastName: string;
  npn: string;
  email: string;
  roleId: string;
  agencyId: string;
  agencyName: string;
}

export interface AdminData {
  _id?: string;
  name?: string;
  npn?: string;
  roleId?: string;
  agencyId?: string;
}

class AdminStore {
  isAccessRevoked: boolean = false;
  account: InsureTrekAccount | null = null;
  agency: InsureTrekAgency | null = null;
  role: string | null = null;

  getAccount = () => this.account;
  setAccount = (account: InsureTrekAccount) => {
    this.account = account;
  };
  getAgency = () => this.agency;
  setAgency = (agency: InsureTrekAgency) => {
    this.agency = agency;
  };
  getRole = () => this.role;
  setRole = (role: string) => {
    this.role = role;
  };

  isActionableAgentFetching: boolean = false;

  setIsActionableAgentFetching(isLoading: boolean): void {
    this.isActionableAgentFetching = isLoading;
  }

  getIsAccessRevoked = (): boolean | null => this.isAccessRevoked;
  setIsAccessRevoked(isAccessRevoked: boolean): void {
    this.isAccessRevoked = isAccessRevoked;
  }

  // TODO: Delete this function
  async loadAllAdminState(
    searchText: string,
    page: number,
    size: number,
    token: string,
    requestBody: Object,
    cancelTokenSource: CancelTokenSource
  ) {
    const response: any = await AdminService.getAllAdminsForCurrentAgency(
      searchText,
      size,
      page,
      token,
      requestBody,
      cancelTokenSource
    );

    const adminData = response.data.data;
    const admins =
      adminData &&
      adminData.map((admin: any) => {
        return {
          id: admin.id,
          name: admin.name,
          role: Array.isArray(admin.roleNames)
            ? admin.roleNames[0]
            : admin.roleNames,
          email: admin.email,
        } as unknown as AllAdmins_;
      });

    return {
      count: response.data.totalCount,
      admins: admins,
    };
  }

  // TODO: Delete this function
  async loadAllInvitedAdminState(
    searchText: string,
    page: number,
    size: number,
    token: string,
    requestBody: object,
    cancelTokenSource: CancelTokenSource
  ) {
    const response: any = await AdminInvitesService.getAllInvitedAdmins(
      searchText,
      size,
      page,
      token,
      requestBody,
      cancelTokenSource
    );

    const inviteData = response.data.data;
    const invites =
      inviteData &&
      inviteData.map((invite: any) => {
        return {
          id: invite.id,
          inviteType: invite.inviteType,
          isExpired: invite.isExpired,
          isAccepted: invite.isAccepted,
          respondedAt: invite.respondedAt ? new Date(invite.respondedAt) : '',
          inviterDetails: {
            accountId: invite.inviterDetails?.accountId,
            account: {
              id: invite.inviterDetails?.account?.id,
              name: invite.inviterDetails?.account?.name,
              email: invite.inviterDetails?.account?.email,
            },
          },
          inviteeDetails: {
            email: invite.inviteeDetails?.email,
            role: invite.inviteeDetails?.role,
          },
          status: invite.status,
          invitedOn: invite.invitedOn ? new Date(invite.invitedOn) : '',
          expiryDate: invite.expiryDate ? new Date(invite.expiryDate) : '',
        };
      });

    return {
      count: response.data.totalCount,
      invites: invites,
    };
  }

  updateName = (firstName: string, middleName: '', lastName: string) => {
    if (!this.account) return;

    this.account.name.firstName = firstName;
    this.account.name.middleName = middleName;
    this.account.name.lastName = lastName;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export const adminStore = new AdminStore();
