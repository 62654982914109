import { CancelTokenSource } from 'axios';
import { ErrorResponse } from '../types/response/errorResponse.type';
import { MessageSuccessResponse } from '../types/response/messageSuccessResponse.type';
import { ObjectSuccessResponse } from '../types/response/objectSuccessResponse.type';
import { PaginatedSuccessResponse } from '../types/response/paginatedSuccessResponse.type';
import { RoleType } from '../enums/roles.enum';
import { UrlConstants } from '../constants/url.constants';
import { UserInvite } from '../types/data/invitedAdmins';
import { apiStore } from '../stores/api.store';
import { filterRequestBody } from '../utils/common.utils';

export class AdminInvitesService {
  private static host = UrlConstants.backend;
  private static modulePath = '/userinvites';

  /**
   * Returns the admin invites sent out by current agency
   * @param agencyId
   * @param size
   * @param page
   * @param token
   * @returns
   */
  static async getAllInvitedAdmins(
    searchString: string,
    size: number,
    page: number,
    token: string,
    requestBody: object = {},
    cancelTokenSource: CancelTokenSource
  ): Promise<PaginatedSuccessResponse<UserInvite>> {
    return new Promise(async (resolve, reject) => {
      try {
        const url_ = new URL(`${this.host}/invite/backoffice`);
        if (searchString)
          url_.searchParams.append(
            'searchString',
            encodeURIComponent(searchString)
          );
        if (size) url_.searchParams.append('size', size.toString());
        if (page) url_.searchParams.append('page', page.toString());
        // Process requestBody using filterRequestBody function
        filterRequestBody(requestBody, url_);
        const response = await apiStore
          .getApiClient(token, cancelTokenSource)
          .get(`${url_}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });
        resolve(response as unknown as PaginatedSuccessResponse<UserInvite>);
      } catch (error: any) {
        console.error('getAllInvitedAdmins Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Returns filter info for users invites table
   * @param token
   * @returns
   */

  static async getFilterInfoForUserInvites(token: string): Promise<any> {
    const path = `/invite/filters`;
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(token)
          .get(`${this.host}${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'BlacklistService :: getFilterInfoForUserAdmins :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Created new admin invite
   * @param recipientEmail
   * @param recipientRoleName
   * @param senderUserId
   * @param agencyId
   * @param npn
   * @param token
   * @returns
   */
  static async createAdminInvite(
    recipientEmail: string,
    recipientRoleName: RoleType,
    token: string
  ): Promise<ObjectSuccessResponse<UserInvite> | ErrorResponse> {
    const path = `/invite`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(
            `${this.host}${path}`,
            {
              inviteeDetails: {
                email: recipientEmail,
                role: recipientRoleName,
              },
              inviteType: 'user',
            },
            {
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          )
          .catch((e) => {
            console.error('error ::', e);
            throw e;
          });
        if (!response) throw new Error('Oops! Something went wrong');

        resolve(response.data as unknown as ObjectSuccessResponse<UserInvite>);
      } catch (error: any) {
        console.error(
          'adminInvitesService :: createAdminInvite Error :: ',
          error?.message
        );
        reject(error.response.data as ErrorResponse);
      }
    });
  }

  /**
   * Resend new admin invite
   * @param inviteId
   * @param senderUserId
   * @param invitedOn
   * @returns
   */
  static async renewAdminInvite(
    senderUserId: string,
    invitedOn: number,
    inviteToken: string,
    token: string
  ): Promise<ObjectSuccessResponse<UserInvite> | ErrorResponse> {
    const path = `/userinvites/${inviteToken}/renew`;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(
            `${this.host}${path}?resendInvite=true`,
            {
              senderUserId: senderUserId,
              invitedOn: invitedOn,
            },
            {
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          )
          .catch((e) => {
            console.error('error ::', e);
            throw e;
          });
        if (!response) throw new Error('Oops! Something went wrong');

        resolve(response.data as unknown as ObjectSuccessResponse<UserInvite>);
      } catch (error: any) {
        console.error(
          'adminInvites :: renewAdminInvite Error :: ',
          error?.message
        );
        reject(error.response.data as ErrorResponse);
      }
    });
  }

  /**
   * Revokes (expires) admin invite using invite token
   * @param inviteId
   * @param token
   * @returns
   */
  static async revokeInvite(
    inviteId: string,
    token: string
  ): Promise<MessageSuccessResponse> {
    const path = `/invite`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .delete(`${this.host}${path}/${inviteId}/revoke`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });
        resolve(response.data as MessageSuccessResponse);
      } catch (error: any) {
        console.error('revokeAdminAccess Error :: ', error?.message);
        reject(error);
      }
    });
  }
  static async RespondInvite(
    inviteResponse: string,
    inviteToken: string,
    token: string
  ): Promise<MessageSuccessResponse> {
    const path = '/userinvites';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore.getApiClient(token).patch(
          `${this.host}${path}/${inviteToken}/respond`,
          { response: inviteResponse },
          {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
        resolve(response.data as MessageSuccessResponse);
      } catch (error: any) {
        console.error('revokeAdminAccess Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * API called from invites page to accept / reject user invite
   * @param inviteResponse
   * @param inviteToken
   * @param token
   * @returns
   */
  static async respondAdminInviteFromPortal(
    inviteResponse: string,
    inviteToken: string,
    token: string
  ): Promise<MessageSuccessResponse> {
    const path = '/userinvites';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore.getApiClient(token).patch(
          `${this.host}${path}/${inviteToken}/respond/portal`,
          { response: inviteResponse },
          {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
        resolve(response.data as MessageSuccessResponse);
      } catch (error: any) {
        console.error('revokeAdminAccess Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * API to fetch all invites for current user email (used for invites page)
   * @param token
   * @returns
   */
  static async findAllInvitesForUser(
    token: string,
    page?: number,
    size?: number,
    agentPortal?: boolean
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url_ = agentPortal
          ? new URL(`${this.host}/invite/producer`)
          : new URL(`${this.host}/invite`);
        if (size) url_.searchParams.append('size', size.toString());
        if (page) url_.searchParams.append('page', page.toString());
        const response = await apiStore.getApiClient(token).get(`${url_}`, {
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
          },
        });
        resolve(response.data);
      } catch (error: any) {
        console.error(
          'adminService:: findInvitesForUser Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * API called from invites page to accept / reject user invite
   * @param inviteResponse
   * @param inviteToken
   * @param token
   * @returns
   */
  static async respondToInvite(
    token: string,
    inviteToken: string,
    inviteResponse: string
  ): Promise<MessageSuccessResponse> {
    const path = '/invite';
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore.getApiClient(token).patch(
          `${this.host}${path}/${inviteToken}`,
          { status: inviteResponse },
          {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
        resolve(response.data as MessageSuccessResponse);
      } catch (error: any) {
        console.error('revokeAdminAccess Error :: ', error?.message);
        reject(error);
      }
    });
  }
}
