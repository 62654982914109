import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import {
  CreateAgentData,
  individualOnboardStore,
} from '../../stores/individualOnboardStore';
import React, { useEffect, useState } from 'react';

import { AgentBiographicInfo } from '../agentBiographicInfo/agentBiographicInfo';
import { AgentOverview } from '../../types/data/allAgents.type';
import { AgentService } from '../../services/agent.service';
import { PayeeEnum } from '../../enums/payee.enum';
import { PaymentTypeEnum } from '../../enums/paymentType.enum';
import { ProducerDetails } from '../../pages/agents/onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import { getNameString } from '../../utils/name.utils';
import { multipleOnboardStore } from '../../stores/multipleOnboardStore';
import { useAuth } from '../../auth/authProvider';

interface paymentConfigModalProps {
  visible: boolean;
  handleVisibility: () => void;
  agents:
    | AgentOverview[]
    | AgentBiographicInfo[]
    | CreateAgentData[]
    | ProducerDetails[]
    | undefined;
  successCallback?: () => void;
  onboardSteps?: boolean;
  setAgents?: (
    renewalPaymentAccount: PayeeEnum,
    initialPayment: PayeeEnum,
    paymentType: PaymentTypeEnum,
    autoRenewal: boolean
  ) => void;
  agentIds?: string[];
  fromMultipleOnboard?: boolean;
}

export interface PaymentConfig {
  initialPaymentPayee: string;
  renewalPaymentPayee: string;
  paymentType: string;
  amountCap: number;
}

function isProducerDetails(agent: any): agent is ProducerDetails {
  return Object.keys((agent as ProducerDetails)?.paymentConfig || {})?.includes(
    'licenseInitialPayee'
  );
}

function PaymentConfigModal(props: paymentConfigModalProps) {
  const [paymentConfigs, setPaymentConfigs] = useState<PaymentConfig>({
    initialPaymentPayee: '',
    renewalPaymentPayee: '',
    paymentType: '',
    amountCap: -1,
  });
  const [autoRenewalEnabled, setAutoRenewEnabled] = useState<boolean>(false);
  const { bearerToken } = useAuth();

  useEffect(() => {
    if (props.agents && props.agents?.length === 1) {
      const firstAgent = props.agents[0];
      const isProducerDetail = isProducerDetails(firstAgent);

      const getInitialPaymentPayee = () =>
        isProducerDetail
          ? (firstAgent as ProducerDetails)?.paymentConfig?.licenseInitialPayee
          : (firstAgent as AgentBiographicInfo)?.paymentConfig
              ?.initialPaymentPayee || '';

      const getRenewalPaymentPayee = () =>
        isProducerDetail
          ? (firstAgent as ProducerDetails)?.paymentConfig?.licenseRenewalPayee
          : (firstAgent as AgentBiographicInfo)?.paymentConfig
              ?.renewalPaymentPayee || '';

      const getPaymentType = () =>
        isProducerDetail
          ? (firstAgent as ProducerDetails)?.paymentConfig?.paymentType
          : (firstAgent as AgentBiographicInfo)?.paymentConfig?.paymentType ||
            '';

      const getAmountCap = () =>
        isProducerDetail
          ? 0
          : (firstAgent as AgentBiographicInfo)?.paymentConfig?.amountCap || 0;

      const getAutoRenewEnabled = () =>
        isProducerDetail
          ? (firstAgent as ProducerDetails)?.paymentConfig
              ?.isAutoRenewalActive || false
          : (firstAgent as AgentBiographicInfo)?.autoRenewEnabled || false;

      setPaymentConfigs({
        initialPaymentPayee: getInitialPaymentPayee(),
        renewalPaymentPayee: getRenewalPaymentPayee(),
        paymentType: getPaymentType(),
        amountCap: getAmountCap(),
      });

      setAutoRenewEnabled(getAutoRenewEnabled());
    } else if (props.fromMultipleOnboard) {
      setPaymentConfigs({
        initialPaymentPayee:
          multipleOnboardStore.paymentConfig.initialPaymentPayee,
        renewalPaymentPayee:
          multipleOnboardStore.paymentConfig.renewalPaymentPayee,
        paymentType: multipleOnboardStore.paymentConfig.paymentType,
        amountCap: multipleOnboardStore.paymentConfig.amountCap || 0,
      });
      setAutoRenewEnabled(multipleOnboardStore.autoRenewEnabled);
    } else {
      setPaymentConfigs({
        initialPaymentPayee: '',
        renewalPaymentPayee: '',
        paymentType: '',
        amountCap: -1,
      });
      setAutoRenewEnabled(false);
    }
  }, [props.agents]);

  const handleSave = async () => {
    if (bearerToken) {
      try {
        setLoading(true);
        await AgentService.updatePaymentConfig(
          props.agents
            ? props.agents.map((agent) => agent.id)
            : props.agentIds
            ? props.agentIds
            : [],
          {
            isAutoRenewalActive: autoRenewalEnabled,
            paymentType: paymentConfigs.paymentType,
            licenseInitialPayee: paymentConfigs.initialPaymentPayee,
            licenseRenewalPayee: paymentConfigs.renewalPaymentPayee,
          },
          bearerToken,
          autoRenewalEnabled
        );
        if (props.onboardSteps) {
          individualOnboardStore.createAgentData.paymentConfig.initialPaymentPayee =
            paymentConfigs.initialPaymentPayee;
          individualOnboardStore.createAgentData.paymentConfig.renewalPaymentPayee =
            paymentConfigs.renewalPaymentPayee;
          individualOnboardStore.createAgentData.paymentConfig.paymentType =
            paymentConfigs.paymentType;
          individualOnboardStore.createAgentData.paymentConfig.amountCap =
            paymentConfigs.amountCap;
          individualOnboardStore.createAgentData.autoRenewEnabled =
            autoRenewalEnabled;
        }
        if (props.fromMultipleOnboard) {
          multipleOnboardStore.paymentConfig.initialPaymentPayee =
            paymentConfigs.initialPaymentPayee;
          multipleOnboardStore.paymentConfig.renewalPaymentPayee =
            paymentConfigs.renewalPaymentPayee;
          multipleOnboardStore.paymentConfig.paymentType =
            paymentConfigs.paymentType;
          multipleOnboardStore.paymentConfig.amountCap =
            paymentConfigs.amountCap;
          multipleOnboardStore.autoRenewEnabled = autoRenewalEnabled;
        }
        props.handleVisibility();
        props.setAgents &&
          props.setAgents(
            paymentConfigs.renewalPaymentPayee as PayeeEnum,
            paymentConfigs.initialPaymentPayee as PayeeEnum,
            paymentConfigs.paymentType as PaymentTypeEnum,
            autoRenewalEnabled
          );
        props.successCallback && props.successCallback();
      } catch (e) {
        console.error('Error :: handleSave :: paymentConfigModal', e);
      } finally {
        setLoading(false);
      }
    }
  };

  const validateSubmit = () => {
    const { initialPaymentPayee, renewalPaymentPayee, paymentType, amountCap } =
      paymentConfigs;
    if (!initialPaymentPayee || !renewalPaymentPayee) {
      return false;
    }
    if (paymentType === PaymentTypeEnum.CAP && (!amountCap || amountCap <= 0)) {
      return false;
    }
    if (
      (initialPaymentPayee === PayeeEnum.AGENCY ||
        renewalPaymentPayee === PayeeEnum.AGENCY) &&
      (!paymentType || paymentType === PaymentTypeEnum.NA)
    ) {
      return false;
    }

    return true;
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      !(
        paymentConfigs.initialPaymentPayee === PayeeEnum.AGENCY ||
        paymentConfigs.renewalPaymentPayee === PayeeEnum.AGENCY
      )
    ) {
      setPaymentConfigs((prev) => ({
        ...prev,
        paymentType: '',
        amountCap: -1,
      }));
    } else {
      setPaymentConfigs((prev) => ({
        ...prev,
        paymentType: PaymentTypeEnum.FULL,
      }));
    }
  }, [paymentConfigs.initialPaymentPayee, paymentConfigs.renewalPaymentPayee]);

  useEffect(() => {
    if (
      !(
        paymentConfigs.renewalPaymentPayee === PayeeEnum.AGENCY &&
        (paymentConfigs.paymentType === PaymentTypeEnum.CAP ||
          paymentConfigs.paymentType === PaymentTypeEnum.FULL)
      )
    ) {
      setAutoRenewEnabled(multipleOnboardStore.autoRenewEnabled);
    }
  }, [paymentConfigs.renewalPaymentPayee, paymentConfigs.paymentType]);

  return (
    <Modal
      open={props.visible}
      onCancel={() => {
        props.handleVisibility();
        setLoading(false);
      }}
      closable={!loading}
      maskClosable={!loading}
      width={'670px'}
      footer={
        <div>
          <Button
            loading={loading}
            disabled={!validateSubmit()}
            htmlType="submit"
            size="large"
            className="button"
            style={{
              width: '100%',
              marginBottom: '10px',
            }}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            size="large"
            style={{
              width: '100%',
              marginLeft: '-5px',
              color: 'var(--secondary-color)',
              border: '1px solid var(--border-color)',
              boxShadow: 'none',
            }}
            onClick={() => {
              props.handleVisibility();
              setLoading(false);
            }}
            disabled={loading}
          >
            Cancel
          </Button>
        </div>
      }
    >
      <Typography.Paragraph
        style={{
          color: 'var(--secondary-color)',
          fontSize: '16px',
          fontWeight: 500,
        }}
      >
        Payment Configuration
      </Typography.Paragraph>
      <hr className="secondary-line" />
      <Typography.Paragraph
        style={{
          color: 'var(--secondary-color)',
          fontSize: '14px',
          fontWeight: '400',
        }}
      >
        Selected Producer(s)
      </Typography.Paragraph>
      {props.agents && (
        <Row>
          <Col span={10}>
            <Typography.Paragraph
              style={{ color: '#787878', fontSize: '12px' }}
            >
              Producer Name
            </Typography.Paragraph>
          </Col>
          <Col>
            <Typography.Paragraph
              style={{ color: '#787878', fontSize: '12px' }}
            >
              NPN
            </Typography.Paragraph>
          </Col>
        </Row>
      )}
      <div style={{ maxHeight: '200px', overflow: 'auto' }}>
        {props.agents?.map((agent) => {
          return (
            <Row>
              <Col span={10}>
                <Typography.Paragraph style={{ fontSize: '12px' }}>
                  {getNameString(agent?.name || '')}
                </Typography.Paragraph>
              </Col>
              <Col>
                <Typography.Paragraph style={{ fontSize: '12px' }}>
                  {agent.npn}
                </Typography.Paragraph>
              </Col>
            </Row>
          );
        })}

        {props.fromMultipleOnboard && (
          <Typography.Paragraph style={{ color: '#787878', fontSize: '12px' }}>
            All the selected producers will be assigned the same payment
            configurations
          </Typography.Paragraph>
        )}
      </div>
      <hr className="secondary-line" />
      <Row>
        <Col span={12}>
          <Typography.Paragraph style={{ fontSize: '12px', marginLeft: '1px' }}>
            Initial payment account
          </Typography.Paragraph>
        </Col>
        <Col span={12}>
          <Typography.Paragraph
            style={{ fontSize: '12px', marginLeft: '12px' }}
          >
            Renewal Payment Account
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row justify={'space-between'} style={{ marginBottom: '22px' }}>
        <Col span={12}>
          <Select
            style={{
              width: '300px',
            }}
            disabled={loading}
            value={paymentConfigs.initialPaymentPayee || undefined}
            onChange={(value) => {
              const paymentType =
                value === PayeeEnum.AGENCY ? PaymentTypeEnum.FULL : '';
              setPaymentConfigs((prev) => ({
                ...prev,
                initialPaymentPayee: value,
                paymentType: paymentType,
              }));
            }}
            placeholder="Select Initial Payee"
          >
            {/* <Select.Option value="Agent">Producer</Select.Option> */}
            <Select.Option value="agency">Agency</Select.Option>
          </Select>
        </Col>
        <Col>
          <Select
            style={{
              width: '300px',
            }}
            disabled={loading}
            value={paymentConfigs.renewalPaymentPayee || undefined}
            onChange={(value) => {
              setPaymentConfigs((prev) => ({
                ...prev,
                renewalPaymentPayee: value,
              }));
            }}
            placeholder="Select Renewal Payee"
          >
            {/* <Select.Option value="Agent">Producer</Select.Option> */}
            <Select.Option value="agency">Agency</Select.Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Typography.Paragraph style={{ fontSize: '12px', marginLeft: '1px' }}>
            Auto Apply
          </Typography.Paragraph>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Switch
            checked={autoRenewalEnabled}
            disabled={
              !(
                paymentConfigs.renewalPaymentPayee === PayeeEnum.AGENCY &&
                (paymentConfigs.paymentType === PaymentTypeEnum.CAP ||
                  paymentConfigs.paymentType === PaymentTypeEnum.FULL)
              ) || loading
            }
            onChange={(value) => {
              setAutoRenewEnabled(value);
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default PaymentConfigModal;
