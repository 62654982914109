import { Admin } from '../types/response/data/admins/admin.types';
import { ArraySuccessResponse } from '../types/response/arraySuccessResponse.type';
import { CancelTokenSource } from 'axios';
import { CreateAdmin } from '../types/request/admins/createAdmin.type';
import { ErrorResponse } from '../types/response/errorResponse.type';
import { MessageSuccessResponse } from '../types/response/messageSuccessResponse.type';
import { ObjectSuccessResponse } from '../types/response/objectSuccessResponse.type';
import { PaginatedSuccessResponse } from '../types/response/paginatedSuccessResponse.type';
import { RegisterAgencyAndAdminDTO } from '../types/request/admins/register-agency-and-admin.type';
import { StateGroup } from '../types/data/stateGroup.types';
import { UrlConstants } from '../constants/url.constants';
import { apiStore } from '../stores/api.store';
import { filterRequestBody } from '../utils/common.utils';

export class AdminService {
  static host = UrlConstants.backend;
  static modulePath = '/admins';

  /**
   * Returns the admin document for current admin
   * @param token
   * @returns
   */
  static async getCurrentAdmin(token: string): Promise<Admin | null> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(`${this.host}${this.modulePath}/me`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });

        let admin = null;

        if (response?.data?.data) admin = response.data.data;

        resolve(admin);
      } catch (error: any) {
        console.error('AdminService Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Returns the admins for the current agency
   * @param agencyId
   * @param size
   * @param page
   * @param token
   * @returns
   */
  static async getAllAdminsForCurrentAgency(
    searchString: string,
    size: number,
    page: number,
    token: string,
    requestBody: any = {},
    cancelTokenSource: CancelTokenSource
  ) {
    const path = `/account/all/backoffice`;
    const url_ = new URL(`${this.host}${path}`);
    url_.searchParams.append('role', 'admin');
    if (searchString)
      url_.searchParams.append(
        'searchString',
        encodeURIComponent(searchString)
      );
    if (size) url_.searchParams.append('size', size.toString());
    if (page) url_.searchParams.append('page', page.toString());
    // Process requestBody using filterRequestBody function
    filterRequestBody(requestBody, url_);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token, cancelTokenSource)
          .get(`${url_}`, {
            data: requestBody,
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });

        resolve(response);
      } catch (error: any) {
        console.error('getAllAdmins Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Returns filter info for users admins table
   * @param token
   * @returns
   */

  static async getFilterInfoForUserAdmins(token: string): Promise<any> {
    const path = `/account/filters`;
    return new Promise(async (resolve, reject) => {
      try {
        const AllLicenses = await apiStore
          .getApiClient(token)
          .get(`${this.host}${path}`);

        resolve(AllLicenses.data);
      } catch (error: any) {
        console.error(
          'BlacklistService :: getFilterInfoForUserAdmins :: Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Revokes the access (removes from agency) for given adminId
   * @param adminId
   * @param role
   * @param token
   * @returns
   */
  static async revokeAdminAccess(
    accountId: string,
    role: string,
    bearerToken: string
  ): Promise<MessageSuccessResponse> {
    const path = `/account/${accountId}/${role}/revoke`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(bearerToken)
          .delete(`${this.host}${path}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });
        resolve(response.data as MessageSuccessResponse);
      } catch (error: any) {
        console.error('revokeAdminAccess Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   *
   * @param token
   * @returns
   */
  static async findInvitesForUser(
    token: string,
    inUserInvite: boolean
  ): Promise<ObjectSuccessResponse<any>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .get(
            `${this.host}/invite/sign-up/my?fromUserInvite=${inUserInvite}`,
            {
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
            }
          );
        resolve(response.data as unknown as ObjectSuccessResponse<any>);
      } catch (error: any) {
        console.error(
          'adminService:: findInvitesForUser Error :: ',
          error?.message
        );
        reject(error);
      }
    });
  }

  /**
   * Onboards new admin having admin invite
   * @param token
   * @param admin
   * @param auth0UserId
   * @param inviteDetails
   * @returns
   */
  static async onboardAdmin(
    token: string,
    admin: CreateAdmin | null,
    inviteDetails: { inviteToken: string; response: string }
  ): Promise<MessageSuccessResponse> {
    const url = `${this.host}/admins/onboard`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore.getApiClient(token).post(url, {
          createAdminDTO: { ...admin },
          inviteDetails,
        });
        resolve(response.data as unknown as MessageSuccessResponse);
      } catch (error: any) {
        console.error('onboardAdmin Error :: ', error?.message);
        reject(error);
      }
    });
  }

  /**
   * Onboards new admin (and creates dummy agency)
   * @param registerAgencyAndAdminDTO
   * @param token
   * @returns
   */
  static async registerAgencyAndAdmin(
    registerAgencyAndAdminDTO: RegisterAgencyAndAdminDTO,
    token: string
  ): Promise<ObjectSuccessResponse<Admin>> {
    const url = `${this.host}/admins/register-agency-and-admin`;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiStore
          .getApiClient(token)
          .post(url, registerAgencyAndAdminDTO);
        resolve(response.data as unknown as ObjectSuccessResponse<Admin>);
      } catch (error: any) {
        reject((error?.response?.data as ErrorResponse) || error);
      }
    });
  }

  /**
   * Updates Admin details given the admin ID
   * @param id
   * @param name
   * @param accesstoken
   * @returns
   */
  static async updateAccountName(
    firstName: string,
    lastName: string,
    accesstoken: string
  ): Promise<
    PaginatedSuccessResponse<StateGroup> | ArraySuccessResponse<StateGroup>
  > {
    return new Promise(async (resolve, reject) => {
      const apiPath = '/account/me/name';
      try {
        const response = await apiStore.getApiClient(accesstoken).patch(
          `${this.host}${apiPath}`,
          {
            firstName,
            lastName,
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          }
        );
        resolve(response.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * Retrieves admin information given the admin ID
   * @param id
   * @param accesstoken
   * @returns
   */
  static async getAdminDetails(accesstoken: string) {
    return new Promise(async (resolve, reject) => {
      const apiPath = '/admins/me';
      try {
        const response = await apiStore
          .getApiClient(accesstoken)
          .get(`${this.host}${apiPath}`, {
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
          });
        resolve(response.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  /**
   * Update Tour Completed Details
   */
  static async updateTourCompleted(accesstoken: string, payload: any) {
    return new Promise(async (resolve, reject) => {
      const apiPath = '/account/tour-flags';
      try {
        const response = await apiStore
          .getApiClient(accesstoken)
          .patch(`${this.host}${apiPath}`, payload);
        resolve(response.data);
      } catch (error: any) {
        reject(error);
      }
    });
  }
}
