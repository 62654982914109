export const STATE_GROUP_NAME: RegExp = /^[a-zA-Z0-9 _-]*$/;
export const EMAIL_REGEX: RegExp =
  /^((?!\.)[\w\-_.+]*[^.])(@[\w-]+)(\.[\w-]+(\.[\w-]+)?[^.\W])$/;
// /^((?!\.)[\w\-_.+]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
export const PASSWORD_REGEX: RegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[^\s]{8,}$|^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^\s]{8,}$|^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])[^\s]{8,}$|^(?=.*[a-z])(?=.*\d)(?=.*[\W_])[^\s]{8,}$|^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[^\s]{8,}$/;
export const NAME_REGEX: RegExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9 .#_-]{1,}$/;

// export const SSN_REGEX: RegExp =
//   /^(?!666|000|9\d{2})\d{3}-?(?!00)\d{2}-?(?!0{4})\d{4}$/;

export const SSN_REGEX: RegExp = /^\d{6,9}$/;

export const NPN_REGEX: RegExp = /^(?!000|666)\d{1,10}$/;

export const FEIN_REGEX: RegExp = /^[1-9]\d?-?\d{7}$/;

export const LAST_NAME_REGEX: RegExp = /\b([a-zA-ZÀ-ÿ][-,a-z. ']+[ ]*)+/;
