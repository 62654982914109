import { RoleType, RoleTypeLabels } from '../../../enums/roles.enum';

import { ProfileType } from '../../../enums/profile.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import { Select } from 'antd';
import { auth0Store } from '../../../stores/auth0Store';
import { hierarchyViewStore } from '../../../stores/hierarchyViewStore';
import { observer } from 'mobx-react-lite';
import { signupStore } from '../../../stores/signupStore';
import { useAuth } from '../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { withRoles } from '../../../auth/useRoles';

function SelectProfile() {
  const { loadUser } = useAuth();
  const navigate = useNavigate();

  const handleSelectProfile = async (value: string) => {
    const profile = auth0Store.findProfileById(value);
    if (profile) {
      signupStore.setIsAppLoading(true);
      auth0Store.setCurrentProfile(profile);
      if (profile.agencyId) {
        hierarchyViewStore.setCurrentAgencyId(profile.agencyId);
      }
      loadUser().then(() => {
        if (profile.role === RoleType.ADMIN) {
          navigate(RouteConstants.allAgents.path);
        } else if (profile.role === RoleType.AGENT) {
          navigate(RouteConstants.agentDashBoard.path);
        }
      });
    }
  };
  return auth0Store?.getProfiles()?.length > 1 ? (
    <div>
      <Select
        // defaultValue={}
        style={{ width: 500 }}
        value={auth0Store.getCurrentProfile()?.id}
        onChange={handleSelectProfile}
        options={auth0Store.getProfiles()?.map((itr) => {
          let label;
          if (itr.role === 'admin')
            label = `${RoleTypeLabels[itr.role as RoleType]} - ${
              itr.agency.name
            }`;
          else if (itr.agency.name)
            label = `${RoleTypeLabels[RoleType.AGENT]} - ${itr.agency.name}`;
          else label = 'General Agent';
          return {
            value: itr.id,
            label: <>{label}</>,
          };
        })}
      />
    </div>
  ) : (
    <></>
  );
}
SelectProfile.displayName = 'SelectProfile';
export default withRoles(observer(SelectProfile), [
  RoleType.SUPER_ADMIN,
  RoleType.AGENT,
  RoleType.ADMIN,
]);
